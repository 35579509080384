import { FC } from 'react';
import Container from 'react-bootstrap/Container';

import './styles/styles.css';
import { ReservationsTable } from '../../Tables/Reservations';
import Header from '../../Header';

export const ReservationsDashboard: FC = () => {
  return (
    <Container fluid className="reservation-list-container">
      <Header title="Reservations" />
      <ReservationsTable />
    </Container>
  );
};
