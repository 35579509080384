import { FC, useState } from 'react';

import { ColDef } from 'ag-grid-community';
import { DataTable } from '../DataTable';

type ReportTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
};

export const ReportTable: FC<ReportTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
}: ReportTableProps) => {
  const [columns] = useState<ColDef<any>[]>([
    { field: 'period', headerName: 'PERIOD', flex: 2 },
    {
      field: 'nightsAvailable',
      headerName: 'NIGHTS AVAILABLE',
      flex: 1,
      sortable: true,
    },
    {
      field: 'totalNightsOccupied',
      headerName: 'NIGHTS SOLD',
      flex: 1,
      sortable: true,
    },

    {
      field: 'occupancyRate',
      headerName: 'OCCUPANCY RATE',
      sortable: true,
      flex: 2,
      valueFormatter(params) {
        return `${params.value}%`;
      },
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
    />
  );
};
