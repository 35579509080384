import { FC, useContext, useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import DateIcon from '../../../assets/date.svg';
import './styles/styles.css';
import Header from '../../Header';
import { CategoryTable } from '../../Tables/Category';
import { Categories } from '../../../modules/interfaces';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import AddCategory from '../../Modals/AddCategory/AddCategory';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';
import { ArticlesContext } from '../../../modules/context/articlesContext';

export const Category: FC = () => {
  const { setSimpleToasts } = useNotifications()!;
  const [isDateShow, setisDateShow] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [editedCategory, setEditedCategory] = useState('');
  const categoryTarget = useRef(null);
  const { credentialsInfo } = useAuth()!;
  const { setLoading } = useLoading()!;

  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const {
    getArticles,
    categoriesData,
    categoriesLoading,

    getCategoriesRequest,
  } = useContext(ArticlesContext)!;

  const [
    { data: addCategoryData, loading: addCategoryLoading, error: addCategoryError },
    createCategoryRequest,
  ] = useRequest<Categories[]>(
    endpoints.CATEGORIES,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    {
      manual: true,
    },
  );

  const [
    { data: editCategoryData, loading: editCategoryLoading, error: editCategoryError },
    editCategoryRequest,
  ] = useRequest<Categories[]>(
    `${endpoints.CATEGORIES}/${selectedCategoryId}`,
    'put',
    {
      authToken: credentialsInfo?.token,
    },
    {
      manual: true,
    },
  );

  const [
    {
      data: deleteCategoryData,
      loading: deleteCategoryLoading,
      error: deleteCategoryError,
    },
    deleteCategoryRequest,
  ] = useRequest<Categories[]>(
    `${endpoints.CATEGORIES}/${selectedCategoryId}`,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    setLoading(
      categoriesLoading ||
        addCategoryLoading ||
        editCategoryLoading ||
        deleteCategoryLoading,
    );
  }, [
    addCategoryLoading,
    categoriesLoading,
    deleteCategoryLoading,
    editCategoryLoading,
    setLoading,
  ]);

  useEffect(() => {
    if (addCategoryData) {
      setSimpleToasts({
        type: 'success',
        message: 'Category added successfully',
        show: true,
      });
      getCategoriesRequest();
    }

    if (addCategoryError) {
      const message = getApiErrorMessage(addCategoryError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [addCategoryData, addCategoryError, setSimpleToasts]);

  useEffect(() => {
    if (editCategoryData) {
      setSelectedCategoryId('');
      setSimpleToasts({
        type: 'success',
        message: 'Category updated successfully',
        show: true,
      });
      getCategoriesRequest();
      getArticles();
    }

    if (editCategoryError) {
      const message = getApiErrorMessage(editCategoryError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [editCategoryData, editCategoryError, setSimpleToasts]);

  useEffect(() => {
    if (deleteCategoryData) {
      setSimpleToasts({
        type: 'success',
        message: 'Category deleted successfully',
        show: true,
      });
      getCategoriesRequest();
      getArticles();
    }

    if (deleteCategoryError) {
      const message = getApiErrorMessage(deleteCategoryError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [deleteCategoryData, deleteCategoryError, setSimpleToasts]);

  const onAddCategory = () => {
    setEditedCategory('');
    setSelectedCategoryId('');
    setAddCategory(true);
  };

  const onEditCategory = (categoriesItem?: Categories) => {
    setEditedCategory(categoriesItem?.name || '');
    setSelectedCategoryId(categoriesItem?.uuid || '');
    setAddCategory(true);
  };

  const onDeleteCategory = (categoriesItem?: Categories) => {
    setEditedCategory(categoriesItem?.name || '');
    setSelectedCategoryId(categoriesItem?.uuid || '');
    setDeleteCategory(true);
  };

  const addCategoryHandler = (category: string) => {
    createCategoryRequest({
      data: {
        name: category,
      },
    });
    setAddCategory(false);
  };

  const editCategoryHandler = (category: string) => {
    editCategoryRequest({
      data: {
        name: category,
      },
    });
    setAddCategory(false);
  };

  const saveCategoryHandler = (category?: string) => {
    if (!category) {
      setSimpleToasts({
        type: 'danger',
        message: 'Category name is required',
        show: true,
      });
      return;
    }
    if (selectedCategoryId) {
      editCategoryHandler(category);
    } else {
      addCategoryHandler(category);
    }
  };

  const deleteCategoryHandler = () => {
    deleteCategoryRequest();
    setDeleteCategory(false);
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title={`Categories — ${categoriesData.length}`} />
      <div className="catagory-container">
        <div
          ref={categoryTarget}
          onClick={() => setisDateShow(!isDateShow)}
          className="category-text-container"
        >
          <img src={DateIcon} alt="date" />
          <div className="category-text-style">Feb 10 — Apr 10, 2024 </div>
          <img src={isDateShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
        </div>
      </div>
      <CategoryTable
        addButtonFunction={onAddCategory}
        addButtonText="Add category"
        searchString=""
        refreshHandler={() => Promise.resolve()}
        tableData={categoriesData}
        onEditCategory={onEditCategory}
        onDeleteCategory={onDeleteCategory}
      />
      <AddCategory
        show={addCategory}
        onSave={category => saveCategoryHandler(category)}
        onHide={() => setAddCategory(false)}
        category={editedCategory}
      />
      <DeleteModal
        show={deleteCategory}
        onDelete={() => deleteCategoryHandler()}
        onHide={() => setDeleteCategory(false)}
        title="Delete Category"
        description="Are you sure you want to delete this category?"
      />
    </Container>
  );
};
