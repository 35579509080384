import { useState, FC, useEffect, useCallback } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';

import { PencilSquare, Trash } from 'react-bootstrap-icons';
import { NewRoleModal } from '../../Modals/NewRoleModal';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { DotDropDown } from '../../Customize/DropDown';
import { config } from '../../../modules/config';
import { endpoints } from '../../../modules/mappers/urls';
import { DataTable } from '../DataTable';
import { AdminTableProps } from '../AdminCategory';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import {
  RoleManagementModal,
  RoleManagementModalData,
} from '../../Modals/RoleManagementModal';
import { RoleResponse, Roles, OptionItem } from '../../../modules/interfaces';
import { ModalType } from '../../../modules/enums/types';
import { CanMulti } from '../../CanMulti';
import { Can } from '../../../modules/utils/can';

interface RolesDotCellRendererProps {
  data?: Roles;
  onClickEdit: (data?: Roles) => void;
  onClickDelete: (data?: Roles) => void;
}

const RolesDotMenu: FC<RolesDotCellRendererProps> = ({
  data,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <CanMulti actions={['update', 'delete']} subject="roles">
      <DotDropDown title="" drop="end">
        <Can I="update" a="roles">
          <Dropdown.Item
            onClick={() => {
              onClickEdit(data);
            }}
          >
            <PencilSquare className="dropdown-icon" />
            Edit Role
          </Dropdown.Item>
        </Can>
        <Can I="delete" a="roles">
          <Dropdown.Item
            onClick={() => {
              onClickDelete(data);
            }}
          >
            <Trash className="dropdown-icon" />
            Delete Role
          </Dropdown.Item>
        </Can>
      </DotDropDown>
    </CanMulti>
  );
};

const getDotMenu = ({ onClickEdit, onClickDelete }: RolesDotCellRendererProps) => {
  const DotMenuCellRenderer: FC<ICellRendererParams<Roles>> = (
    params: ICellRendererParams<Roles>,
  ) => {
    const { data } = params;
    return (
      <RolesDotMenu data={data} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
    );
  };

  return DotMenuCellRenderer;
};

export const RoleManagment: FC<AdminTableProps> = ({ changeState }) => {
  const { setSimpleToasts } = useNotifications()!;
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;
  const [tableData, setTableData] = useState<Roles[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showNewRoleModal, setShowNewRoleModal] = useState(false);
  const [currentRole, setCurrentRole] = useState<Roles>();

  const [editModal, setEditModal] = useState<RoleManagementModalData>({
    modalName: 'Edit Role',
    cancelButton: 'Cancel',
    saveButton: 'Save',
    modalType: ModalType.EDIT,
    handleClose: () => {
      // TODO: need to implement
    },
    handleRefresh: () => {
      // TODO: need to implement
    },
  });

  const [{ data, loading, error }, refetch] = useRequest<RoleResponse>(
    endpoints.ROLES,
    'get',
    {
      authToken: credentialsInfo?.token,
      params: {
        currentPage: 0,
        pageCount: config.tables.balances.paginationSize,
      },
    },
  );

  const [{ loading: insertLoading, data: insertResult, error: insertError }, insertRole] =
    useRequest<Roles>(
      endpoints.ROLES,
      'post',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (data) {
      const { result } = data;
      setTableData(result.data);
    }
  }, [data, error, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (insertResult) {
      refetch();
    }
  }, [insertResult, insertError, setSimpleToasts, refetch]);

  useEffect(() => {
    setLoading(loading || insertLoading);
  }, [loading, insertLoading, setLoading]);

  const showAddRoleModal = () => {
    setShowNewRoleModal(!showNewRoleModal);
  };

  const handleCreateRole = (
    name: string,
    permssions: OptionItem[],
    assignedUsers: OptionItem[],
  ) => {
    insertRole({
      data: {
        name,
        permissions: permssions.map(item => item.value),
        assignedUsers: assignedUsers.map(item => item.value),
      },
    });
  };

  const refreshHandler = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const onClickEdit = useCallback(
    (role?: Roles) => {
      if (role) {
        setCurrentRole(role);
        setShowEditModal(true);
        setEditModal({
          modalName: 'Edit Role',
          cancelButton: 'Cancel',
          saveButton: 'Save',
          handleClose: () => {
            setShowEditModal(false);
          },
          modalType: ModalType.EDIT,
          handleRefresh: refreshHandler,
        });
      }
    },
    [setCurrentRole, setShowEditModal, setEditModal, refreshHandler],
  );

  const onClickDelete = useCallback(
    (role?: Roles) => {
      if (role) {
        setCurrentRole(role);
        setShowEditModal(true);
        setEditModal({
          modalName: 'Delete Role',
          cancelButton: 'Cancel',
          saveButton: 'Yes',
          handleClose: () => {
            setShowEditModal(false);
          },
          modalType: ModalType.DELETE,
          handleRefresh: refreshHandler,
        });
      }
    },
    [setCurrentRole, setShowEditModal, setEditModal, refreshHandler],
  );

  const [columns] = useState<ColDef<Roles>[]>([
    { field: 'name', headerName: 'ROLE NAME', width: 200 },
    {
      valueGetter: params => {
        const { data: role } = params;
        const permissions = role ? role.permissions.join(',') : '-';

        return permissions;
      },
      headerName: 'PERMISSIONS',
      width: 200,
    },
    { field: 'createdAt', headerName: 'DATE CREATED', width: 200 },
    {
      headerName: 'ACTION',
      colId: 'action',
      cellClass: 'actionCol justify-content-end',
      width: 200,
      cellRenderer: getDotMenu({ onClickEdit, onClickDelete }),
    },
  ]);

  return (
    <>
      <Row className="admin-article-table">
        <Col sm={12}>
          <Row className="d-flex align-items-center justify-content-between table-title-section">
            <Col sm={3}>
              <h5 className="table-title">Role Managment</h5>
              <span className="table-sub-title">
                Amount of Roles: <span className="bold">{tableData.length}</span>
              </span>
            </Col>
            <Col sm={3} className="d-flex align-items-center justify-content-end">
              <button
                type="button"
                onClick={showAddRoleModal}
                className="invite-user position-relative"
              >
                Add Role
              </button>
            </Col>
          </Row>
          <DataTable
            refreshHandler={refreshHandler}
            rowData={tableData}
            columnDefs={columns}
            pagination
            changeState={changeState}
            paginationPageSize={config.tables.balances.paginationSize}
            onRowClicked={(e: RowClickedEvent<Roles>) => {
              if (e.api.getFocusedCell()?.column.getColId() === 'action') {
                return;
              }
              const { data: roles } = e;
              if (roles) {
                // TODO: need to implement
              }
            }}
          />
        </Col>
      </Row>
      {showNewRoleModal && (
        <NewRoleModal
          showAddRole={showNewRoleModal}
          handleClose={showAddRoleModal}
          handleSave={handleCreateRole}
        />
      )}
      {showEditModal && (
        <RoleManagementModal
          data={currentRole}
          cancelButton={editModal?.cancelButton}
          modalName={editModal?.modalName}
          saveButton={editModal?.saveButton}
          handleClose={editModal?.handleClose}
          handleRefresh={editModal.handleRefresh}
          modalType={editModal.modalType}
        />
      )}
    </>
  );
};
