import { FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DocumentExportType } from '../../../modules/enums/types';
import CSVIcon from '../../../assets/csv-file-icon.svg';
import ExcelIcon from '../../../assets/xlsx-file-icon.svg';
import PDFIcon from '../../../assets/pdf-file-icon.svg';
import './styles/styles.css';

type ReportExportOptionsProp = {
  show: boolean;
  selectedExportType: DocumentExportType;
  onHide: () => void;
  onStartExport: () => void;
  onSelectExportOption: (type: DocumentExportType) => void;
};

const ReportExportOptions: FC<ReportExportOptionsProp> = ({
  show,
  selectedExportType,
  onHide,
  onStartExport,
  onSelectExportOption,
}) => {
  const [exportType, setSelectedExportType] = useState<DocumentExportType>(
    selectedExportType || DocumentExportType.PDF,
  );

  useEffect(() => {
    setSelectedExportType(selectedExportType || DocumentExportType.PDF);
  }, [selectedExportType]);

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">
          Select an export format
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="export-option-container">
          <div
            className={`export-option ${
              exportType === DocumentExportType.PDF ? 'selected' : ''
            }`}
            onClick={() => {
              onSelectExportOption(DocumentExportType.PDF);
              setSelectedExportType(DocumentExportType.PDF);
            }}
          >
            <img src={PDFIcon} alt="PDF" className="export-icon" />
          </div>
          <div
            className={`export-option ${
              exportType === DocumentExportType.XLSX ? 'selected' : ''
            }`}
            onClick={() => {
              onSelectExportOption(DocumentExportType.XLSX);
              setSelectedExportType(DocumentExportType.XLSX);
            }}
          >
            <img src={ExcelIcon} alt="XLSX" className="export-icon" />
          </div>
          <div
            className={`export-option ${
              exportType === DocumentExportType.CSV ? 'selected' : ''
            }`}
            onClick={() => {
              onSelectExportOption(DocumentExportType.CSV);
              setSelectedExportType(DocumentExportType.CSV);
            }}
          >
            <img src={CSVIcon} alt="CSV" className="export-icon" />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button className="edit-guest-modal-button-save" onClick={onStartExport}>
          Start Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportExportOptions;
