import { FC, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ColDef, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { DataTable } from '../DataTable';
import { config } from '../../../modules/config';
import { Categories } from '../../../modules/interfaces';
import DeleteIcon from '../../../assets/delete-icon.svg';
import EditblackIcon from '../../../assets/edit-icon.svg';
import VerticalDotMenuIcon from '../../../assets/vertical-dot-menu.svg';
import { CanMulti } from '../../CanMulti';
import { Can } from '../../../modules/utils/can';

type CategoryTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
  onEditCategory: (category?: Categories) => void;
  onDeleteCategory: (category?: Categories) => void;
  addButtonFunction?: () => void;
  addButtonText?: string;
};

export const CategoryTable: FC<CategoryTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
  onEditCategory,
  onDeleteCategory,
  addButtonFunction,
  addButtonText,
}: CategoryTableProps) => {
  const renderMenuIcon = (item: RowClickedEvent<Categories>) => {
    return (
      <CanMulti actions={['update', 'delete']} subject="categories">
        <div className="menu-icon-container-style">
          <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <Can I="update" a="categories">
                    <div
                      onClick={e => {
                        document.body.click();
                        onEditCategory(item.data);
                      }}
                      style={{ paddingBottom: '5px' }}
                      className="edit-damage-report-container"
                    >
                      <img
                        className="edit-icon-image-style"
                        src={EditblackIcon}
                        alt="edit-black-icon"
                      />
                      <div className="edit-text-style">Edit</div>
                    </div>
                  </Can>

                  <Can I="delete" a="categories">
                    <div
                      style={{ marginTop: '10px' }}
                      className="edit-damage-report-container"
                      onClick={e => {
                        document.body.click();
                        onDeleteCategory(item.data);
                      }}
                    >
                      <img
                        className="edit-icon-image-style"
                        src={DeleteIcon}
                        alt="edit-black-icon"
                      />
                      <div className="delete-text-style">Delete</div>
                    </div>
                  </Can>
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <img src={VerticalDotMenuIcon} alt="vertical-icon" />
            </div>
          </OverlayTrigger>
        </div>
      </CanMulti>
    );
  };

  const [columns] = useState<ColDef<Categories>[]>([
    { field: 'name', headerName: 'CATEGORY NAME', flex: 4 },

    {
      valueGetter: params => {
        const { data: category } = params;
        const articlesCount = category?.articles?.length || 0;
        return articlesCount;
      },
      headerName: 'ARTICLES IN CATEGORY',

      flex: 2,
    },

    {
      field: 'createdAt',
      headerName: 'CREATION DATE',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<Categories, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromISO(value || '').toFormat('MM-dd-yyyy');
        return formattedDate;
      },
      cellStyle: {
        color: '#757474',
      },
      flex: 2,
    },

    {
      headerName: 'ACTIONS',
      cellRenderer: renderMenuIcon,
      flex: 1,
    },
  ]);

  return (
    <DataTable
      addButtonFunction={addButtonFunction}
      addButtonText={addButtonText}
      component="categories"
      refreshHandler={refreshHandler}
      searchString={searchString}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
      pagination
      paginationPageSize={config.tables.reservations.paginationSize}
    />
  );
};
