import { createContext } from 'react';

import { AbilityTuple, MongoAbility, MongoQuery } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { defineAbilityFor } from './abilities';

const defaultAbility = defineAbilityFor([]);
export const AbilityContext =
  createContext<MongoAbility<AbilityTuple, MongoQuery>>(defaultAbility);

export const Can = createContextualCan(AbilityContext.Consumer);
