import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { routes } from '../../modules/mappers/urls';

import { AbilityContext } from '../../modules/utils/can';

export const ProtectedRouteByRoles = ({
  children,
  component,
}: {
  children: React.ReactNode;
  component: string;
}): JSX.Element => {
  const location = useLocation();
  const ability = useContext(AbilityContext);
  let element = children;

  if (!ability.can('view', component)) {
    element = <Navigate to={routes.HOME} replace state={{ from: location }} />;
  }

  return element as JSX.Element;
};
