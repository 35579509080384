import { createMongoAbility, AbilityBuilder, MongoAbility } from '@casl/ability';

export function defineAbilityFor(permissions: string[]) {
  const { can, build } = new AbilityBuilder(createMongoAbility);

  permissions.forEach((item: string) => {
    const permission = item.split(':');

    can(permission[1], permission[0]);
  });

  return build();
}

export type AppAbility = MongoAbility<[string, string]>;
