import { FC, useState, useCallback, useRef, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Button, Overlay, Popover } from 'react-bootstrap';
import './styles/styles.css';
import { DateTime } from 'luxon';
import Header from '../../Header';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import DateIcon from '../../../assets/date.svg';
import UnCheckRadioIcon from '../../../assets/uncheck-radio-icon.svg';
import CheckRadioIcon from '../../../assets/check-radio-icon.svg';
import ExpertIcon from '../../../assets/expert.svg';
import { monthList, yearList } from '../../../constants/generalData';
import { AddonsRevenueReportTable } from '../../Tables/AddonsRevenueReports';
import { endpoints } from '../../../modules/mappers/urls';
import {
  AddonsRevenueReportByCategory,
  AddonsRevenueReportByAddon,
} from '../../../modules/interfaces/reports';
import { UserPropertiesContext } from '../../../modules/context/userPropertiesContext';

import { useAuth, useRequest, useLoading } from '../../../modules/hooks';

import {
  DocumentExportType,
  PDFPageSizes,
  PDFPageOrientation,
  FieldTextAlignment,
} from '../../../modules/enums/types';

import {
  ReportExporter,
  ExportOptions,
  CustomFormat,
} from '../../../modules/utils/ReportExporter';

import ReportExportOptions from '../../Modals/ReportExportOptions';

function getMonthNumber(monthName: string): number | null {
  const dt = DateTime.fromFormat(monthName, 'MMMM', { locale: 'en' });
  return dt.isValid ? dt.month : null;
}

export const AddonsRevenueReports: FC = () => {
  const { credentialsInfo } = useAuth()!;
  const [reportData, setReportData] = useState<
    AddonsRevenueReportByAddon[] | AddonsRevenueReportByCategory[]
  >([]);
  const { selectedProperty } = useContext(UserPropertiesContext)!;
  const { setLoading } = useLoading()!;
  const [view, setView] = useState<string>('by Month');
  const [viewFinal, setViewFinal] = useState<string>('by Month');
  const [addonsView, setRoomView] = useState<string>('Total by category');
  const [addonsViewFinal, setRoomViewFinal] = useState<string>('Total by category');
  const viewArray = ['by Quarter', 'by Month'];
  const addonsArray = ['Total by category', 'Total by addon'];
  const [dateShow, setDateShow] = useState(false);
  const dateTarget = useRef(null);
  const addonsTarget = useRef(null);
  const [viewShow, setViewShow] = useState(false);
  const [addonsShow, setRoomShow] = useState(false);
  const viewTarget = useRef(null);
  const fromMonth = useRef(null);
  const toMonth = useRef(null);
  const fromYear = useRef(null);
  const toYear = useRef(null);
  const [fromMonthShow, setFromMonthShow] = useState(false);
  const [toMonthShow, setToMonthShow] = useState(false);
  const [fromYearShow, setFromYearShow] = useState(false);
  const [toYearShow, setToYearShow] = useState(false);
  const [fromMonthValue, setFromMonthValue] = useState('January');
  const [toMonthValue, setToMonthValue] = useState('December');
  const [fromYearValue, setFromYearValue] = useState(
    DateTime.now().minus({ years: 3 }).year.toString(),
  );
  const [toYearValue, setToYearValue] = useState(DateTime.now().year.toString());
  const [fromMonthValueFinal, setFromMonthValueFinal] = useState<string>('January');
  const [toMonthValueFinal, setToMonthValueFinal] = useState<string>('December');
  const [fromYearValueFinal, setFromYearValueFinal] = useState<string>(
    DateTime.now().minus({ years: 3 }).year.toString(),
  );
  const [toYearValueFinal, setToYearValueFinal] = useState<string>(
    DateTime.now().year.toString(),
  );
  const [tableType, setTableType] = useState('categoryMonth');
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState<DocumentExportType>(
    DocumentExportType.PDF,
  );
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const refreshHandler = useCallback(async () => {
    console.log('test');
  }, []);

  const getAddonsRevenueReportUrl = () => {
    const urlQueriesValues = {
      periodType: viewFinal === 'by Month' ? 'month' : 'quarter',
      reportType: addonsViewFinal === 'Total by category' ? 'category' : 'addon',
      yearFrom: fromYearValueFinal,
      yearTo: toYearValueFinal,
      monthFrom: getMonthNumber(fromMonthValueFinal),
      monthTo: getMonthNumber(toMonthValueFinal),
    };
    const url = `${endpoints.REPORTS}/addons-revenue?${Object.entries(
      urlQueriesValues,
    ).reduce((acc, [key, value]) => {
      if (value) {
        return `${acc}${key}=${value}&`;
      }
      return acc;
    }, '')}`;
    return url;
  };

  const [
    { data: addonsRevenueReportResult, loading: loadingRoomsRevenueReport },
    getAddonsRevenueReport,
  ] = useRequest<AddonsRevenueReportByAddon[] | AddonsRevenueReportByCategory[]>(
    getAddonsRevenueReportUrl(),
    'get',
    {
      authToken: credentialsInfo?.token,
      propertyId: selectedProperty?.property.uuid || undefined,
    },
  );

  const onViewItemClick = (reportItem: string) => {
    if (addonsShow) {
      setRoomView(reportItem);
    } else {
      setView(reportItem);
    }
  };

  const renderViewItem = (reportItem: string) => {
    const value = addonsShow ? addonsView : view;
    const isSelected = value === reportItem;
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onViewItemClick(reportItem)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckRadioIcon : UnCheckRadioIcon}
          alt="check-icon"
        />
        <div style={{ textTransform: 'none' }}>{reportItem}</div>
      </div>
    );
  };

  const onCancleView = () => {
    setView(viewFinal);
    setViewShow(false);
    setRoomShow(false);
    setRoomView(addonsViewFinal);
  };

  const setTableTypeState = (viewA: string, addonsType: string) => {
    if (viewA === 'by Quarter' && addonsType === 'Total by category') {
      setTableType('categoryQuarter');
    } else if (viewA === 'by Month' && addonsType === 'Total by category') {
      setTableType('categoryMonth');
    } else if (viewA === 'by Quarter' && addonsType === 'Total by addon') {
      setTableType('addonQuarter');
    } else if (viewA === 'by Month' && addonsType === 'Total by addon') {
      setTableType('addonMonth');
    }
  };

  const onApplyView = () => {
    if (view) {
      setViewFinal(view);
      setViewShow(false);
      setTableTypeState(view, addonsViewFinal);
    }
  };
  const onApplyRoom = () => {
    if (addonsView) {
      setRoomViewFinal(addonsView);
      setRoomShow(false);
      setTableTypeState(viewFinal, addonsView);
    }
  };

  const onCancledate = () => {
    setDateShow(false);
    setFromMonthValue(fromMonthValueFinal);
    setToMonthValue(toMonthValueFinal);
    setFromYearValue(fromYearValueFinal);
    setToYearValue(toYearValueFinal);
  };

  const onApplyDate = () => {
    setDateShow(false);
    if (fromMonthValue) {
      setFromMonthValueFinal(fromMonthValue);
    }
    if (toMonthValue) {
      setToMonthValueFinal(toMonthValue);
    }
    if (fromYearValue) {
      setFromYearValueFinal(fromYearValue);
    }
    if (toYearValue) {
      setToYearValueFinal(toYearValue);
    }
  };

  const onFromMonthClick = () => {
    setFromMonthShow(true);
  };

  const onFromYearClick = () => {
    setFromYearShow(true);
  };

  const onToMonthClick = () => {
    setToMonthShow(true);
  };

  const onToYearClick = () => {
    setToYearShow(true);
  };

  const onMonthClick = (isFrom: boolean, month: string) => {
    setFromMonthShow(false);
    setToMonthShow(false);
    if (isFrom) {
      setFromMonthValue(month);
    } else {
      setToMonthValue(month);
    }
  };

  const onYearClick = (isFrom: boolean, year: string) => {
    setFromYearShow(false);
    setToYearShow(false);
    if (isFrom) {
      setFromYearValue(year);
    } else {
      setToYearValue(year);
    }
  };

  const renderDateByYear = () => {
    return (
      <div className="drop-down-container-status">
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">From</div>
              <div
                onClick={onFromYearClick}
                ref={fromYear}
                className="year-calender-container "
              >
                <div className="year-text-style">{fromYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">To</div>
              <div
                onClick={onToYearClick}
                ref={toYear}
                className="year-calender-container "
              >
                <div className="year-text-style">{toYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="apply-and-cancle-container">
          <div onClick={onCancledate} className="cancle-text-container">
            Cancel
          </div>
          <div onClick={onApplyDate} className="apply-text-container">
            Apply
          </div>
        </div>
      </div>
    );
  };

  const renderMonthList = (isFrom: boolean) => {
    return (
      <div style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
        {monthList.map(item => (
          <div
            onClick={() => onMonthClick(isFrom, item)}
            className="dropdown-item-calendar-picker"
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const renderYearList = (isFrom: boolean) => {
    return (
      <div style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
        {yearList.map(item => (
          <div
            onClick={() => onYearClick(isFrom, item.toString())}
            className="dropdown-item-calendar-picker"
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const renderDateByMonth = () => {
    return (
      <div className="drop-down-container-status">
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">From</div>
              <div
                ref={fromMonth}
                className="year-calender-container by-month-container"
                onClick={() => onFromMonthClick()}
              >
                <div className="year-text-style">{fromMonthValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromMonthShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div
                onClick={onFromYearClick}
                ref={fromYear}
                className="year-calender-container by-month-text"
              >
                <div className="year-text-style">{fromYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={fromYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="artical-dropdown-container">
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div className="from-and-to-text-style">To</div>
              <div
                ref={toMonth}
                onClick={() => onToMonthClick()}
                className="year-calender-container by-month-container "
              >
                <div className="year-text-style">{toMonthValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toMonthShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
          <div className="date-text-main-container">
            <div className="date-text-container ">
              <div
                onClick={onToYearClick}
                ref={toYear}
                className="year-calender-container by-month-text"
              >
                <div className="year-text-style">{toYearValue}</div>
                <img
                  className="drop-down-icon-style"
                  src={toYearShow ? DropDownOpenIcon : DropDownIcon}
                  alt="drop-down"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="apply-and-cancle-container">
          <div onClick={onCancledate} className="cancle-text-container">
            Cancel
          </div>
          <div onClick={onApplyDate} className="apply-text-container">
            Apply
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (addonsRevenueReportResult) {
      setReportData(addonsRevenueReportResult.result);
    }
  }, [addonsRevenueReportResult]);

  useEffect(() => {
    const isLoading = loadingRoomsRevenueReport;
    setLoading(isLoading);
  }, [loadingRoomsRevenueReport, setLoading]);

  const exportReport = async () => {
    if (reportData.length > 0) {
      const customFormat = new Map<string, CustomFormat>([
        [
          'name',
          {
            parseCurrency: false,
            prepend: '',
            append: '',
            textAlign: FieldTextAlignment.LEFT,
          },
        ],
        [
          'category',
          {
            parseCurrency: false,
            prepend: '',
            append: '',
            textAlign: FieldTextAlignment.LEFT,
          },
        ],
        [
          'sales',
          {
            parseCurrency: false,
            prepend: '',
            append: '',
            textAlign: FieldTextAlignment.CENTER,
          },
        ],
        [
          'SALES',
          {
            parseCurrency: false,
            prepend: '',
            append: '',
            textAlign: FieldTextAlignment.CENTER,
          },
        ],
      ]);

      const exportOptions: ExportOptions<
        AddonsRevenueReportByAddon | AddonsRevenueReportByCategory
      > = {
        reportName: 'Addons Report',
        subtitle: `From ${fromMonthValueFinal}, ${fromYearValueFinal} to ${toMonthValueFinal}, ${toYearValueFinal}`,
        hiddenFields: ['propertyId', 'year', 'month', 'quarter'],
        fixedStartFields: ['period', 'name', 'category'],
        fixedEndFields: ['total_sales', 'total'],
        pdfPageSize: PDFPageSizes.A3,
        pdfPageOrientation: PDFPageOrientation.LANDSCAPE,
        customFormat,
      };
      const exporter = new ReportExporter<
        AddonsRevenueReportByAddon | AddonsRevenueReportByCategory
      >(reportData, exportOptions);
      await exporter.export(selectedExportType);
      setIsExportInProgress(false);
    }
  };

  const startExport = () => {
    setIsExportInProgress(true);
    setShowExportOptions(false);
    exportReport();
  };

  const onExportReport = () => {
    setShowExportOptions(true);
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title="Addons Revenue Reports" />
      <div className="artical-text-main-container">
        <div className="artical-dropdown-container ">
          <div
            ref={addonsTarget}
            onClick={() => setRoomShow(!addonsShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              <div>{addonsViewFinal}</div>
            </div>
            <img src={addonsShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
          <div
            ref={dateTarget}
            onClick={() => setViewShow(!viewShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              <div className="view-text-style">View: </div>
              {viewFinal}
            </div>
            <img src={viewShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
          <div className="catagory-container">
            <div
              ref={viewTarget}
              onClick={() => setDateShow(!dateShow)}
              style={{ marginLeft: 0 }}
              className="date-rage-picker-text-container"
            >
              <img src={DateIcon} alt="date" />
              <div className="category-text-style">Last year</div>
              <img
                className="drop-down-icon-style"
                src={dateShow ? DropDownOpenIcon : DropDownIcon}
                alt="drop-down"
              />
            </div>
          </div>
        </div>
        <Button
          className="add-artical-container"
          onClick={onExportReport}
          {...(isExportInProgress && { disabled: true })}
        >
          <img src={ExpertIcon} alt="plus-icon" />
          <div className="add-artical-text-style">
            {isExportInProgress ? 'Exporting...' : 'Export'}
          </div>
        </Button>
      </div>

      <Overlay
        onHide={() => setDateShow(false)}
        target={viewTarget.current}
        show={dateShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {viewFinal === 'by Month' ? renderDateByMonth() : renderDateByYear()}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setViewShow(false)}
        target={dateTarget.current}
        rootClose
        show={viewShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {viewArray.map(renderViewItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleView} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyView} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setRoomShow(false)}
        target={addonsTarget.current}
        rootClose
        show={addonsShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {addonsArray.map(renderViewItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleView} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyRoom} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setFromMonthShow(false)}
        target={fromMonth.current}
        rootClose
        show={fromMonthShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderMonthList(true)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setToMonthShow(false)}
        target={toMonth.current}
        rootClose
        show={toMonthShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderMonthList(false)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setFromYearShow(false)}
        target={fromYear.current}
        rootClose
        show={fromYearShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">{renderYearList(true)}</Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setToYearShow(false)}
        target={toYear.current}
        rootClose
        show={toYearShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            {renderYearList(false)}
          </Popover.Body>
        </Popover>
      </Overlay>
      <AddonsRevenueReportTable
        searchString=""
        tableData={reportData}
        refreshHandler={refreshHandler}
        tabletype={tableType}
      />
      <ReportExportOptions
        show={showExportOptions}
        onHide={() => setShowExportOptions(false)}
        onStartExport={startExport}
        selectedExportType={selectedExportType}
        onSelectExportOption={(type: DocumentExportType) => setSelectedExportType(type)}
      />
      <div className="bottom-total-view"> </div>
    </Container>
  );
};
