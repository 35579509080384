import { useEffect, useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AbilityTuple, MongoAbility, MongoQuery } from '@casl/ability';
import { AppContext } from './modules/context/appContext';
import { ProtectedRoute } from './components/Routes';
import './App.css';
import { ForgotPassword } from './components/Forms/ForgotPassword';
import { CreateNewPassword } from './components/Forms/CreateNewPassword';
import { Layout } from './components/Layout';
import { ReservationsDashboard } from './components/Dashboards/Reservations';
import { ReservationDetails } from './components/Dashboards/ReservationDetails';
import { config } from './modules/config';
import { routes } from './modules/mappers/urls';
import { Articles } from './components/Dashboards/Articles';
import { Category } from './components/Dashboards/Categories';
import { ArticlesDetails } from './components/Forms/Articles/Details';
import { Login } from './components/Forms/Login';
import { ArticleSearch } from './components/Forms/Articles/ArticleSearch';
import { ArticleAdmin } from './components/Dashboards/Admin';
import { AccepInvite } from './components/Forms/AcceptInvite';
import { Users } from './components/Dashboards/Users';
import { Properties } from './components/Dashboards/Properties';
import { Rooms } from './components/Dashboards/Rooms';
import { InboxDashboard } from './components/Dashboards/Inbox';
import { Packages } from './components/Dashboards/Packages';
import { PackagesView } from './components/Dashboards/Packages/PackagesView';
import GuestPageLayout from './components/GuestPages/GuestPageLayout';
import { ReservationPackageProvider } from './components/Providers/ReservationPackage';
import { UserProvider } from './components/Providers/UserProperties';
import { Reports } from './components/Dashboards/Reports';
import { RevenueReports } from './components/Dashboards/RevenueReports';
import { RoomRevenueReports } from './components/Dashboards/RoomRevenueReports';
import { AddonsRevenueReports } from './components/Dashboards/AddonsRevenueReports';
import { ReservationsReports } from './components/Dashboards/ReservationsReports';
import { UserManagement } from './components/Dashboards/UserManagement';
import { RoleManagement } from './components/Dashboards/RoleManagement';
import { UserArticles } from './components/Forms/Articles/UserArticles';
import { ArticlesProvider } from './components/Providers/Articles';
import { AllUsersProvider } from './components/Providers/AllUsers';
import { ProtectedRouteByProperty } from './components/Routes/protectedByProperty';
import { AbilityContext } from './modules/utils/can';
import { defineAbilityFor } from './modules/utils/abilities';
import { useAuth } from './modules/hooks';
import { ProtectedRouteByRoles } from './components/Routes/protectedByRoles';

function App() {
  const stripePromise = loadStripe(config.stripe.publicKey);
  const { permissions } = useAuth() || {};
  const navigate = useNavigate();
  const location = useLocation();

  const [ability, setAbility] = useState<MongoAbility<AbilityTuple, MongoQuery>>(
    defineAbilityFor([]),
  );

  useEffect(() => {
    if (!permissions?.length) return;

    setAbility(defineAbilityFor(permissions));

    const [gpPath] = routes.GUEST_PORTAL.split('/:');

    if (!location.pathname.startsWith(gpPath)) {
      const origin = location.state?.from?.pathname
        ? `${location.state?.from?.pathname}${location.state?.from?.search || ''}`
        : routes.HOME;

      navigate(origin);
    }
  }, [permissions]);

  const initCtx = useMemo(() => {
    return { stripePromise };
  }, [stripePromise]);

  return (
    <AppContext.Provider value={initCtx}>
      <Routes>
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.FORGOTPASSWORD} element={<ForgotPassword />} />
        <Route path={routes.CREATENEWPASSWORD} element={<CreateNewPassword />} />
        <Route path={routes.ACCEPT_INVITE} element={<AccepInvite />} />
        <Route
          path={routes.HOME}
          element={
            <AbilityContext.Provider value={ability}>
              <ProtectedRoute>
                <UserProvider>
                  <AllUsersProvider>
                    <ArticlesProvider>
                      <ReservationPackageProvider>
                        <Layout />
                      </ReservationPackageProvider>
                    </ArticlesProvider>
                  </AllUsersProvider>
                </UserProvider>
              </ProtectedRoute>
            </AbilityContext.Provider>
          }
        >
          <Route
            path={routes.USERS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="users">
                  <Users />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.PROPERTIES}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="properties">
                  <Properties />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ROOMS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="rooms">
                  <Rooms />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.RESERVATIONS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByProperty>
                  <ProtectedRouteByRoles component="reservations">
                    <ReservationsDashboard />
                  </ProtectedRouteByRoles>
                </ProtectedRouteByProperty>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.RESERVATION_DETAILS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="reservations">
                  <ReservationDetails />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.INBOX}
            element={
              <ProtectedRoute>
                <ProtectedRouteByProperty>
                  <ProtectedRouteByRoles component="inbox">
                    <InboxDashboard />
                  </ProtectedRouteByRoles>
                </ProtectedRouteByProperty>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ARTICLES}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="articles">
                  <Articles />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.CATEGORIES}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="categories">
                  <Category />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ARTICLES_ADMIN}
            element={
              <ProtectedRoute>
                <ArticleAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ARTICLES_DETAILS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="articles_dashboard">
                  <ArticlesDetails subpage={false} />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.USERS_ARTICLES}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="articles_dashboard">
                  <UserArticles />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ARTICLES_SEARCH}
            element={
              <ProtectedRoute>
                <ArticleSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.PACKAGES}
            element={
              <ProtectedRoute>
                <ProtectedRouteByProperty>
                  <ProtectedRouteByRoles component="packages">
                    <Packages />
                  </ProtectedRouteByRoles>
                </ProtectedRouteByProperty>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.PACKAGES_MANAGEMENT}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="packages_management">
                  <PackagesView />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.REPORTS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="occupancy_reports">
                  <Reports />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.REVENUEREPORTS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="revenue_reports">
                  <RevenueReports />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ROOMREVENUEREPORTS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="room_revenue_reports">
                  <RoomRevenueReports />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ADDONSREVENUEREPORTS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="addons_revenue_reports">
                  <AddonsRevenueReports />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.RESERVATIONSREPORTS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="reservations_reports">
                  <ReservationsReports />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.USERS_MANAGEMENT}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="users">
                  <UserManagement />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ROLE_MANAGEMENT}
            element={
              <ProtectedRoute>
                <ProtectedRouteByRoles component="roles">
                  <RoleManagement />
                </ProtectedRouteByRoles>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={routes.GUEST_REGISTRATION} element={<GuestPageLayout />} />
        <Route path={routes.FEEDBACK} element={<GuestPageLayout />} />
        <Route path={routes.GUEST_PORTAL} element={<GuestPageLayout />} />

        <Route path="*" element={<Login />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
