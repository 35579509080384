import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Badge, Container, Image, Overlay, Popover } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Person,
  BuildingGear,
  ChatText,
  Newspaper,
  BoxSeam,
  ClipboardData,
  CalendarEvent,
  DoorOpen,
} from 'react-bootstrap-icons';

import './styles/styles.css';
import DownArrow from '../../assets/down-arrow.svg';
import DotMenu from '../../assets/dot-menu.svg';
import Icon from '../../assets/Icon-book-nest.svg';

import UpArrow from '../../assets/up-arrow.svg';
import User from '../../assets/user.svg';
import UserProfile from '../../assets/user-profile.svg';
import UserSetting from '../../assets/user-setting.svg';
import UserLogout from '../../assets/user-logout.svg';
import { useAuth } from '../../modules/hooks';
import { routes } from '../../modules/mappers/urls';
import Property from './property';
import { ReservationPackageContext } from '../../modules/context/guestPackageContext';
import { UserPropertiesContext } from '../../modules/context/userPropertiesContext';
import { AbilityContext, Can } from '../../modules/utils/can';

type SubMenu = { title: string; path: string; value: string; badgeNumber?: number };

interface PageItemProps {
  name: string;
  path?: string;
  icon: ReactNode;
  multiple?: boolean;
  expand: boolean;
  subMenu?: Array<SubMenu>;
  pageItemBadge?: number;
}

const PageItem: FunctionComponent<PageItemProps> = ({
  name,
  path,
  icon,
  multiple,
  expand,
  subMenu,
  pageItemBadge,
}: PageItemProps) => {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (subMenu) {
      const subMenuSelect = subMenu?.filter(item => item.path === location.pathname);
      if (!(location.pathname === path || (subMenuSelect && subMenuSelect.length > 0))) {
        setOpen(false);
      }
    }
  }, [location]);

  const selected = useMemo(() => {
    const subMenuSelect = subMenu?.filter(item => item.path === location.pathname);
    return location.pathname === path || (subMenuSelect && subMenuSelect.length > 0);
  }, [location, path]);

  const handleClick = useCallback(() => {
    if (expand) {
      setOpen(!isOpen);
    }
    if (path) {
      navigate(path);
    }
  }, [navigate, path, expand, isOpen]);

  const subMenuSelected = useCallback(
    (item: SubMenu) => {
      return location.pathname === item.path;
    },
    [location],
  );

  const handleSubMenuClick = useCallback(
    (item: SubMenu) => {
      navigate(item.path);
    },
    [navigate],
  );

  return (
    <>
      <Container
        className={
          selected
            ? 'sidebar_container page_item_color'
            : ' sidebar_container page_item_container'
        }
        onClick={handleClick}
      >
        <Container
          style={{ color: selected ? '#15CF5F' : '#909090' }}
          className="sidebar_container page_icon"
        >
          {icon}
        </Container>
        {expand && (
          <div className={selected ? 'page_name_select' : 'page_name'}>
            {name}
            {pageItemBadge && (
              <Badge bg="warning" text="dark" style={{ marginLeft: '10px' }}>
                {pageItemBadge}
              </Badge>
            )}
          </div>
        )}
        {expand && multiple && <img alt={name} src={isOpen ? UpArrow : DownArrow} />}
      </Container>
      {isOpen &&
        expand &&
        subMenu?.map((item: SubMenu) => {
          return (
            <Container
              className="sidebar_container sub_menu_container"
              onClick={() => handleSubMenuClick(item)}
            >
              <div
                className="sub_menu_name"
                style={{ color: subMenuSelected(item) ? '#FFFFFF' : '#909090' }}
              >
                {item.title}

                {item.badgeNumber && item.badgeNumber > 0 ? (
                  <Badge
                    bg="warning"
                    text="dark"
                    style={{
                      marginLeft: '10px',
                      padding: '1px 6px 1px 6px',
                      fontSize: '10px',
                    }}
                  >
                    {item.badgeNumber}
                  </Badge>
                ) : null}
              </div>
            </Container>
          );
        })}
    </>
  );
};

interface SidebarProps {
  expand: boolean;
  hidden?: boolean;
}

const Sidebar: FunctionComponent<SidebarProps> = ({
  expand,
  hidden = false,
}: SidebarProps) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { signOut, credentialsInfo } = useAuth()!;
  const [pendingPackagesSize, setPendingPackagesSize] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const ability = useContext(AbilityContext);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, [window.innerWidth]);

  const { selectedProperty } = useContext(UserPropertiesContext)!;

  const filterSubMenuByPermissions = (
    subMenu: { title: string; value: string; path: string }[],
  ) => {
    return subMenu.filter(item => ability.can('view', item.value)); // eslint-disable-line
  };

  const articleSubMenu = [
    {
      title: 'Dashboard',
      value: 'articles_dashboard',
      path: routes.USERS_ARTICLES,
    },
    { title: 'Articles', value: 'articles', path: routes.ARTICLES },
    { title: 'Categories', value: 'categories', path: routes.CATEGORIES },
  ];
  const packageSubMenu = [
    {
      title: 'Add-ons',
      value: 'packages',
      path: routes.PACKAGES,
      badgeNumber: pendingPackagesSize,
    },
    {
      title: 'Add-ons Management',
      value: 'packages_management',

      path: routes.PACKAGES_MANAGEMENT,
    },
  ];
  const packageManagementSubMenu = [
    {
      title: 'Add-ons Management',
      value: 'packages_management',
      path: routes.PACKAGES_MANAGEMENT,
    },
  ];

  const reportSubMenu = [
    { title: 'Occupancy Reports', value: 'occupancy_reports', path: routes.REPORTS },
    { title: 'Revenue Reports', value: 'revenue_reports', path: routes.REVENUEREPORTS },
    {
      title: 'Room Revenue Reports',
      value: 'room_revenue_reports',
      path: routes.ROOMREVENUEREPORTS,
    },
    {
      title: 'Addons Revenue Reports',
      value: 'addons_revenue_reports',
      path: routes.ADDONSREVENUEREPORTS,
    },
    {
      title: 'Reservation Reports',
      value: 'reservations_reports',
      path: routes.RESERVATIONSREPORTS,
    },
  ];
  const userSubMenu = [
    { title: 'Users', value: 'users', path: routes.USERS_MANAGEMENT },
    { title: 'Roles', value: 'roles', path: routes.ROLE_MANAGEMENT },
  ];

  const filteredReportSubMenu = filterSubMenuByPermissions(reportSubMenu);
  const filteredPackagesSubMenu = selectedProperty
    ? filterSubMenuByPermissions(packageSubMenu)
    : filterSubMenuByPermissions(packageManagementSubMenu);
  const filteredArticleSubMenu = filterSubMenuByPermissions(articleSubMenu);
  const filteredUserSubMenu = filterSubMenuByPermissions(userSubMenu);

  const onUserItemClick = (title: string) => {
    setShow(false);
    switch (title) {
      case 'Log out':
        signOut();
        break;
      default:
    }
  };

  const renderUserItem = (icon: string, title: string) => {
    return (
      <div className="user_item" onClick={() => onUserItemClick(title)}>
        <img alt="user_item_img" src={icon} />
        <div className="user_action">{title}</div>
      </div>
    );
  };

  const reservationPackageList = useContext(ReservationPackageContext);
  const { reservationPackages } = reservationPackageList!;

  useEffect(() => {
    if (!reservationPackages) return;

    const pendingPacakgesSize = reservationPackages.filter(
      item => item.status === 'PENDING',
    ).length;

    setPendingPackagesSize(pendingPacakgesSize);
  }, [reservationPackages, reservationPackageList]);

  return (
    <Container
      className={`sidebar_container ${isMobile ? 'float-sidebar' : ''} ${
        !hidden ? 'visible' : ''
      }`}
      fluid
      style={{
        width: expand ? '275px' : '75px',
      }}
    >
      <Container fluid className=" sidebar_container main_container">
        <Container className="logo_container">
          <Image className="logo_image" src={Icon} alt="BookNest Icon" />
          {expand && <div className="logo_text">Lodging</div>}
        </Container>
        <Property expand={expand} />
        <Container className="sidebar_container page_list_container">
          <Can I="view" a="reservations">
            {selectedProperty && (
              <PageItem
                name="Reservations"
                path={routes.RESERVATIONS}
                icon={<CalendarEvent size={20} />}
                expand={expand}
              />
            )}
          </Can>

          <Can I="view" a="inbox">
            {selectedProperty && (
              <PageItem
                name="Inbox"
                path={routes.INBOX}
                icon={<ChatText size={20} />}
                expand={expand}
              />
            )}
          </Can>

          {filteredArticleSubMenu.length > 0 && (
            <PageItem
              name="Articles"
              icon={<Newspaper size={20} />}
              multiple
              expand={expand}
              subMenu={filteredArticleSubMenu}
            />
          )}

          {filteredPackagesSubMenu.length > 0 && (
            <PageItem
              name="Add-ons"
              icon={<BoxSeam size={20} />}
              multiple
              expand={expand}
              subMenu={filteredPackagesSubMenu}
              pageItemBadge={
                pendingPackagesSize > 0 && selectedProperty
                  ? pendingPackagesSize
                  : undefined
              }
            />
          )}

          <Can I="view" a="properties">
            <PageItem
              name="Property Management"
              path={routes.PROPERTIES}
              icon={<BuildingGear size={20} />}
              expand={expand}
            />
          </Can>

          <Can I="view" a="rooms">
            <PageItem
              name="Rooms"
              path={routes.ROOMS}
              icon={<DoorOpen size={20} />}
              expand={expand}
            />
          </Can>

          {filteredUserSubMenu.length > 0 && (
            <PageItem
              name="Users Management"
              icon={<Person size={20} />}
              expand={expand}
              subMenu={filteredUserSubMenu}
              multiple
            />
          )}

          {filteredReportSubMenu.length > 0 && (
            <PageItem
              name="Reports"
              icon={<ClipboardData size={20} />}
              expand={expand}
              subMenu={filteredReportSubMenu}
              multiple
            />
          )}
        </Container>
      </Container>
      <Container className="sidebar_container user_container">
        <div className="user_divider" />
        <Container
          className={
            expand
              ? 'sidebar_container user_details_container'
              : 'sidebar_container user_details_enclosed'
          }
        >
          <img className="user_image" alt="user" src={User} />
          {expand && (
            <div className="property_details_container">
              <div className="property__name_text">{credentialsInfo?.name}</div>
              <div className="property_text">{credentialsInfo?.email}</div>
            </div>
          )}
          {expand && (
            <div className="menu-three-dot" ref={target} onClick={() => setShow(!show)}>
              <img alt="menu" src={DotMenu} />
            </div>
          )}
          <Overlay
            rootClose
            target={target.current}
            show={show}
            placement="right-start"
            onHide={() => setShow(false)}
          >
            <Popover id="popover-positioned-bottom">
              <Popover.Body className="user_overlay">
                {renderUserItem(UserProfile, 'Profile')}
                {renderUserItem(UserSetting, 'Settings')}
                <div className="user_overlay_divider" />
                {renderUserItem(UserLogout, 'Log out')}
              </Popover.Body>
            </Popover>
          </Overlay>
        </Container>
      </Container>
    </Container>
  );
};

export default Sidebar;
