import { FC } from 'react';
import { ColDef } from 'ag-grid-community';
import { DataTable } from '../DataTable';
import { formatCurrency } from '../../../modules/utils/formatCurrency';

type RoomRevenueReportsTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
  tabletype: string;
};

const generateColumns = (data: any[]): ColDef<any>[] => {
  if (!data || data.length === 0) return [];

  const firstRow = data[0];
  const allFields = Object.keys(firstRow);

  const hiddenFields = ['propertyId', 'year', 'month', 'quarter'];

  const fixedStartFields = ['period', 'roomNumber', 'roomType'];

  const fixedEndFields = ['total'];

  const visibleFields = allFields.filter(field => !hiddenFields.includes(field));

  const numericFields = allFields.filter(field => {
    const value = parseFloat(firstRow[field]);
    return !Number.isNaN(value);
  });

  const customFieldNames: Record<string, string> = {
    roomNumber: 'ROOM',
    roomType: 'ROOM TYPE',
  };

  const extractHeaderName = (field: string) => {
    if (customFieldNames[field]) return customFieldNames[field];
    return field.toUpperCase().replace(/_/g, ' ');
  };

  const renderTotalText = (item: any) => {
    return <div className="total-text">{formatCurrency(item.data.total)}</div>;
  };

  const startColumns: ColDef<any>[] = fixedStartFields
    .filter(field => visibleFields.includes(field))
    .map(field => ({
      field,
      headerName: extractHeaderName(field),
      flex: 1,
      sortable: true,
      pinned: 'left' as const,
    }));

  const endColumns: ColDef<any>[] = fixedEndFields
    .filter(field => visibleFields.includes(field))
    .map(field => ({
      field,
      headerName: extractHeaderName(field),
      flex: 1,
      sortable: true,
      cellRenderer: field === 'total' ? renderTotalText : undefined,
    }));

  const dynamicColumns: ColDef<any>[] = visibleFields
    .filter(field => !fixedStartFields.includes(field) && !fixedEndFields.includes(field))
    .map(field => ({
      field,
      headerName: extractHeaderName(field),
      flex: 1,
      sortable: true,
      valueFormatter: numericFields.includes(field)
        ? params => formatCurrency(params.value)
        : undefined,
    }));

  return [...startColumns, ...dynamicColumns, ...endColumns];
};

export const RoomRevenueReportTable: FC<RoomRevenueReportsTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
  tabletype,
}) => {
  const columns = generateColumns(tableData);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      key={tabletype}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
    />
  );
};
