import { FC, useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import { Button } from 'react-bootstrap';

import { ColDef, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { MewsReservation } from '../../../modules/interfaces';

import { BadgeCellRenderer, DataTable } from '../../Tables/DataTable';
import { roomSort } from '../../../modules/utils/tableSorts';

interface PropertyConfigModalProps {
  selectedReservations?: MewsReservation[];

  show: boolean;
  handleClose: () => void;
  setConfirmedReservation?: (reservation: MewsReservation) => void;
}

export const paymentStatusColorMapper: Record<string, string> = {
  CONFIRMED: 'primary',
  STARTED: 'warning',
};

export const GuestReservationTableModal: FC<PropertyConfigModalProps> = ({
  selectedReservations,
  show,
  handleClose,
  setConfirmedReservation,
}) => {
  const [searchString] = useState<string>('');
  const onClose = () => {
    handleClose();
  };

  const handleBookingDetailsData = (item: MewsReservation) => {
    if (setConfirmedReservation) {
      setConfirmedReservation(item);
      handleClose();
    }
  };
  const [columns] = useState<ColDef<MewsReservation>[]>([
    {
      valueGetter: params => {
        const { data: reservation } = params;
        const resource = reservation?.Resource;
        const name = resource
          ? `${resource.Name} -  ${resource.ResourceName}`
          : 'Not assigned';

        return name;
      },
      headerName: 'ROOM',
      flex: 1,
      sortable: true,
      comparator: (valueA, valueB) => roomSort(valueA, valueB),
    },

    {
      field: 'ScheduledStartUtc',
      headerName: 'ARRIVAL DATE',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<MewsReservation, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromISO(value || '').toFormat('dd LLL yyyy');

        return formattedDate;
      },
      cellStyle: {
        color: '#757474',
      },
      flex: 1,
    },
    {
      field: 'EndUtc',
      headerName: 'DEPARTURE DATE',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<MewsReservation, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromISO(value || '').toFormat('dd LLL yyyy');

        return formattedDate;
      },
      cellStyle: {
        color: '#757474',
      },
      flex: 1,
    },

    {
      field: 'State',
      headerName: 'STATUS',
      sortable: true,
      cellRenderer: BadgeCellRenderer,
      flex: 1,
    },
  ]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      size="xl"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="h5">Guest reservation table</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bdr package-table-container">
          {selectedReservations && selectedReservations.length > 0 ? (
            <DataTable
              searchString={searchString}
              changeState={0}
              rowData={selectedReservations}
              columnDefs={columns}
              pagination
              paginationPageSize={5}
              onRowClicked={(e: RowClickedEvent<MewsReservation>) =>
                handleBookingDetailsData(e.data!)
              }
            />
          ) : (
            <div className="no-reservation-note-text">No Add-on was selected</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div>
          <Button className="cancel-button" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
