import { FC, useEffect, useState, useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import Dropdown from 'react-bootstrap/Dropdown';
import { PencilSquare, Trash, Clipboard, Gear } from 'react-bootstrap-icons';

import { DataTable, ImageLabelCellRenderer } from '../DataTable';
import {
  useAuth,
  useRequest,
  useLoading,
  useNotifications,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { endpoints } from '../../../modules/mappers/urls';
import { config } from '../../../modules/config';
import { DotDropDown } from '../../Customize/DropDown';
import { Property } from '../../../modules/interfaces';
import { ConfirmModal, ModalState } from '../../Modals/Confirm';
import { PropertyEditorModal } from '../../Modals/PropertyEditor';
import { PropertyConfigModal } from '../../Modals/PropertyConfig';
import { CanMulti } from '../../CanMulti';
import { Can } from '../../../modules/utils/can';

interface PropertyDotCellRendererProps {
  data?: Property;
  onClickDeleteProperty: (data?: Property) => void;
  onClickEditConfigProperty: (data?: Property) => void;
  onClickEditProperty: (data?: Property) => void;
  onClickDuplicateProperty: (data?: Property) => void;
}

const PropertyDotMenu: FC<PropertyDotCellRendererProps> = ({
  data,
  onClickDeleteProperty,
  onClickEditConfigProperty,
  onClickEditProperty,
  onClickDuplicateProperty,
}) => {
  return (
    <CanMulti actions={['create', 'update', 'delete']} subject="properties">
      <DotDropDown title="" drop="end">
        <Can I="create" a="properties">
          <Dropdown.Item
            onClick={() => {
              onClickDuplicateProperty(data);
            }}
          >
            <Clipboard className="dropdown-icon me-1" />
            Duplicate Property
          </Dropdown.Item>
        </Can>

        <Can I="update" a="properties">
          <Dropdown.Item
            onClick={() => {
              onClickEditProperty(data);
            }}
          >
            <PencilSquare className="dropdown-icon me-1" />
            Edit Property
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              onClickEditConfigProperty(data);
            }}
          >
            <Gear className="dropdown-icon me-1" />
            Settings
          </Dropdown.Item>
        </Can>
        <Can I="delete" a="properties">
          <Dropdown.Item
            onClick={() => {
              onClickDeleteProperty(data);
            }}
          >
            <Trash className="dropdown-icon me-1" />
            Delete Property
          </Dropdown.Item>
        </Can>
      </DotDropDown>
    </CanMulti>
  );
};

const getDotMenu = ({
  onClickDeleteProperty,
  onClickEditConfigProperty,
  onClickEditProperty,
  onClickDuplicateProperty,
}: PropertyDotCellRendererProps) => {
  const DotMenuCellRenderer: FC<ICellRendererParams<Property>> = (
    params: ICellRendererParams<Property>,
  ) => {
    const { data } = params;
    return (
      <PropertyDotMenu
        data={data}
        onClickDeleteProperty={onClickDeleteProperty}
        onClickEditConfigProperty={onClickEditConfigProperty}
        onClickEditProperty={onClickEditProperty}
        onClickDuplicateProperty={onClickDuplicateProperty}
      />
    );
  };

  return DotMenuCellRenderer;
};

export const PropertiesTable: FC = () => {
  const { setSimpleToasts } = useNotifications()!;
  const [editData, setEditData] = useState<Property>();
  const [configPropertyData, setConfigPropertyData] = useState<Property>();
  const [updateUId, setUpdateUId] = useState<string>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [duplicateModal, setDuplicateModal] = useState<boolean>(false);
  const [configModal, setConfigModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Property[]>([]);
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [confirmModal, setConfirmModal] = useState<ModalState>({
    show: false,
    title: '',
    body: '',
    handler: () => {
      // TODO: need to implement
    },
  });

  const [{ data, loading, error }, refetchProperties] = useRequest<Property[]>(
    endpoints.PROPERTIES,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  const deleteUrl = `${endpoints.PROPERTIES}/${updateUId}`;
  const [
    { loading: deleteLoading, data: deleteResult, error: deleteError },
    deleteProperty,
  ] = useRequest<string>(
    deleteUrl,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const handleAddProperty = () => {
    setDuplicateModal(false);
    setUpdateUId('');
    setEditData(undefined);
    setEditModal(true);
  };

  const closeConfirmModal = () => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
  };

  const handleDeleteProperty = useCallback(() => {
    setConfirmModal({
      ...confirmModal,
      show: false,
    });
    deleteProperty();
  }, [confirmModal, setConfirmModal, deleteProperty]);

  const onClickEditProperty = useCallback(
    (property?: Property) => {
      if (property) {
        setDuplicateModal(false);
        setUpdateUId(property.uuid);
        setEditData(property);
        setEditModal(true);
      }
    },
    [setUpdateUId, setEditData, setEditModal],
  );

  const onClickDuplicateProperty = useCallback(
    (property?: Property) => {
      if (property) {
        setDuplicateModal(true);
        setUpdateUId(property.uuid);
        setEditData(property);
        setEditModal(true);
      }
    },
    [setUpdateUId, setEditData, setEditModal, setDuplicateModal, editData],
  );

  const onClickEditConfigProperty = useCallback(
    (property?: Property) => {
      if (property) {
        setConfigPropertyData(property);
        setConfigModal(true);
      }
    },
    [setUpdateUId, setConfigModal],
  );

  const onClickDeleteProperty = useCallback(
    (property?: Property) => {
      if (property) {
        setUpdateUId(property.uuid);
        setConfirmModal({
          show: true,
          title: 'Delete this property?',
          body: 'This action cannot be undone.',
          handler: handleDeleteProperty,
        });
      }
    },
    [setUpdateUId, setConfirmModal, handleDeleteProperty],
  );

  useEffect(() => {
    setLoading(loading || deleteLoading);
  }, [loading, deleteLoading, setLoading]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
      const mockArray = Array(10);
      const mockData: Property[] = mockArray.fill(0, 0, 10).map(() => ({
        uuid: '',
        name: 'The Coach House',
        city: 'Salem',
        state: 'Ma',
        streetNumber: '20',
        streetName: 'New',
      }));
      setTableData(mockData);
    }
    if (data) {
      setTableData(data.result);
    }
  }, [loading, data, error, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (deleteError) {
      const message = getApiErrorMessage(deleteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (deleteResult) {
      refetchProperties();
    }
  }, [deleteResult, deleteError, setSimpleToasts, setLoading, refetchProperties]);

  useEffect(() => {
    refetchProperties();
  }, [refetchProperties]);

  const refreshProperties = useCallback(async () => {
    await refetchProperties();
  }, [refetchProperties]);

  const [columns] = useState<ColDef<Property>[]>([
    {
      valueGetter: params => {
        const { data: property } = params;
        const name = property ? `${property.name}` : '-';
        return {
          name,
          img: property?.logoUrl || 'Icon-coachhousing.svg',
        };
      },
      cellRenderer: ImageLabelCellRenderer,
      headerName: 'Property name',

      flex: 3,
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 2,
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 2,
    },

    {
      colId: 'action',
      cellClass: 'actionCol justify-content-end',

      cellRenderer: getDotMenu({
        onClickDeleteProperty,
        onClickEditConfigProperty,
        onClickEditProperty,
        onClickDuplicateProperty,
      }),
      flex: 1,
    },
  ]);

  return (
    <>
      <DataTable
        addButtonFunction={handleAddProperty}
        addButtonText="Add property"
        component="properties"
        refreshHandler={refreshProperties}
        changeState={0}
        rowData={tableData}
        columnDefs={columns}
        pagination
        paginationPageSize={config.tables.balances.paginationSize}
      />
      <PropertyEditorModal
        show={editModal}
        handleClose={() => {
          setEditModal(false);
        }}
        data={editData}
        refetchProperties={refetchProperties}
        duplicate={duplicateModal}
      />
      <PropertyConfigModal
        show={configModal}
        handleClose={() => {
          setConfigModal(false);
        }}
        data={configPropertyData}
      />
      <ConfirmModal
        show={confirmModal.show}
        title={confirmModal.title}
        body={confirmModal.body}
        handleCancel={closeConfirmModal}
        handleConfirm={handleDeleteProperty}
      />
    </>
  );
};
