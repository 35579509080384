import { FC, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ColDef, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { BadgeCellRenderer, DataTable } from '../DataTable';
import { config } from '../../../modules/config';
import { Article } from '../../../modules/interfaces';
import DeleteIcon from '../../../assets/delete-icon.svg';
import EditblackIcon from '../../../assets/edit-icon.svg';
import VerticalDotMenuIcon from '../../../assets/vertical-dot-menu.svg';
import ArchiveIcon from '../../../assets/archive.svg';
import DraftIcon from '../../../assets/draft.svg';
import { routes } from '../../../modules/mappers/urls';
import { ArticleStatus } from '../../../modules/enums/status';
import { Can } from '../../../modules/utils/can';
import { CanMulti } from '../../CanMulti';

type ArticleTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
  onEditArticle: (article?: Article) => void;
  onDeleteArticle: (article?: Article) => void;
  onChangeStatus: (article?: Article, articleStatus?: string) => void;
  addButtonFunction?: () => void;
  addButtonText?: string;
};

export const ArticleTable: FC<ArticleTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
  onEditArticle,
  onDeleteArticle,
  onChangeStatus,
  addButtonFunction,
  addButtonText,
}: ArticleTableProps) => {
  const navigate = useNavigate();

  const renderMenuIcon = (params: RowClickedEvent<Article>) => {
    return (
      <CanMulti actions={['create', 'update', 'delete']} subject="articles">
        <div className="menu-icon-container-style">
          <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <Can I="update" a="articles">
                    {params.data?.status === ArticleStatus.DRAFT && (
                      <div
                        onClick={e => {
                          document.body.click();
                          onEditArticle(params.data);
                        }}
                        style={{ paddingBottom: '8px' }}
                        className="edit-damage-report-container"
                      >
                        <img
                          className="edit-icon-image-style"
                          src={EditblackIcon}
                          alt="edit-black-icon"
                        />
                        <div className="edit-text-style">Edit</div>
                      </div>
                    )}

                    {params.data?.status !== ArticleStatus.ARCHIVED && (
                      <div
                        onClick={e => {
                          document.body.click();
                          onChangeStatus(params.data, ArticleStatus.ARCHIVED);
                        }}
                        style={{ paddingTop: '8px', paddingBottom: '8px' }}
                        className="edit-damage-report-container"
                      >
                        <img
                          className="edit-icon-image-style"
                          src={ArchiveIcon}
                          alt="edit-black-icon"
                        />
                        <div className="edit-text-style">Move to Archive</div>
                      </div>
                    )}

                    {params.data?.status !== ArticleStatus.DRAFT && (
                      <div
                        onClick={e => {
                          document.body.click();
                          onChangeStatus(params.data, ArticleStatus.DRAFT);
                        }}
                        style={{ paddingTop: '8px', paddingBottom: '8px' }}
                        className="edit-damage-report-container"
                      >
                        <img
                          className="edit-icon-image-style"
                          src={DraftIcon}
                          alt="edit-black-icon"
                        />
                        <div className="edit-text-style">Move to Draft</div>
                      </div>
                    )}

                    {params.data?.status !== ArticleStatus.PUBLISHED && (
                      <div
                        onClick={e => {
                          document.body.click();
                          onChangeStatus(params.data, ArticleStatus.PUBLISHED);
                        }}
                        style={{ paddingTop: '8px', paddingBottom: '8px' }}
                        className="edit-damage-report-container"
                      >
                        <img
                          className="edit-icon-image-style"
                          src={DraftIcon}
                          alt="edit-black-icon"
                        />
                        <div className="edit-text-style">Move to Published</div>
                      </div>
                    )}
                  </Can>

                  <Can I="delete" a="articles">
                    {params.data?.status !== ArticleStatus.PUBLISHED && (
                      <div
                        style={{ paddingTop: '8px' }}
                        className="edit-damage-report-container"
                        onClick={e => {
                          document.body.click();
                          onDeleteArticle(params.data);
                        }}
                      >
                        <img
                          className="edit-icon-image-style"
                          src={DeleteIcon}
                          alt="edit-black-icon"
                        />
                        <div className="delete-text-style">Delete</div>
                      </div>
                    )}
                  </Can>
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <img src={VerticalDotMenuIcon} alt="vertical-icon" />
            </div>
          </OverlayTrigger>
        </div>
      </CanMulti>
    );
  };

  const [columns] = useState<ColDef<Article>[]>([
    { field: 'name', headerName: 'ARTICLE TITLE', flex: 3 },
    {
      valueGetter: params => {
        const { data: article } = params;
        const resource = article?.categories;
        if (resource) {
          return resource[0]?.name || '-';
        }
        return '';
      },

      headerName: 'CATEGORY',
      flex: 1,
      sortable: true,
    },
    {
      valueGetter: params => {
        const { data: article } = params;
        const customer = article?.createdBy;
        const name = customer ? `${customer.firstName} ${customer.lastName}` : '-';
        return name;
      },
      headerName: 'AUTHOR',

      flex: 2,
      sortable: true,
    },

    {
      field: 'createdAt',
      headerName: 'CREATION DATE',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<Article, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromISO(value || '').toFormat('MM-dd-yyyy');
        return formattedDate;
      },
      cellStyle: {
        color: '#757474',
      },
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      sortable: true,
      flex: 1,
      cellRenderer: BadgeCellRenderer,
    },
    {
      headerName: 'ACTIONS',
      flex: 1,
      cellRenderer: renderMenuIcon,
    },
  ]);

  return (
    <DataTable
      addButtonFunction={addButtonFunction}
      addButtonText={addButtonText}
      component="articles"
      refreshHandler={refreshHandler}
      searchString={searchString}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
      pagination
      paginationPageSize={config.tables.reservations.paginationSize}
      onRowClicked={(e: RowClickedEvent<Article>) => {
        const focusCell = e.api.getFocusedCell();
        if (focusCell?.column.getUserProvidedColDef()?.headerName === 'ACTIONS') {
          return;
        }
        navigate(
          {
            pathname: routes.ARTICLES_DETAILS,
            search: `?id=${e.data?.uuid}`,
          },
          { state: { user: 'Admin' } },
        );
      }}
    />
  );
};
