import { FC, ReactNode, useContext } from 'react';
import { AbilityContext } from '../../modules/utils/can';

interface CanMultiProps {
  actions: string[];
  subject: string;
  requireAll?: boolean;
  children: ReactNode;
}

export const CanMulti: FC<CanMultiProps> = ({
  actions,
  subject,
  requireAll = false,
  children,
}) => {
  const ability = useContext(AbilityContext);

  let element = children;

  const hasPermissions = requireAll
    ? actions.every(action => ability.can(action, subject)) // eslint-disable-line
    : actions.some(action => ability.can(action, subject)); // eslint-disable-line

  console.log(hasPermissions);

  if (!hasPermissions) {
    element = null;
  }

  return element as JSX.Element;
};
