import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { pdf, Document, Page, Text, View } from '@react-pdf/renderer';
import { jsonToCSV } from 'react-papaparse';
import { formatCurrency } from './formatCurrency';

import {
  DocumentExportType,
  PDFPageOrientation,
  PDFPageSizes,
  FieldTextAlignment,
} from '../enums/types';

export interface ExportOptions<S> {
  reportName?: string;
  subtitle?: string;
  fixedStartFields?: string[];
  fixedEndFields?: string[];
  visibleFields?: string[];
  hiddenFields?: string[];
  customFieldNames?: Record<string, string>;
  summaryData?: S;
  pdfPageOrientation?: PDFPageOrientation;
  pdfPageSize?: PDFPageSizes;
  customFormat?: Map<string, CustomFormat>;
}

export interface CustomFormat {
  parseCurrency: boolean;
  prepend?: string;
  append?: string;
  textAlign?: FieldTextAlignment;
  replace?: [string, string];
}

interface parseResult {
  value: string;
  isCurrency: boolean;
  textAlign?: FieldTextAlignment;
}

function parseIfCurrency(value: string): parseResult {
  const numberValue = parseFloat(value);
  return Number.isNaN(numberValue)
    ? { value: String(value), isCurrency: false }
    : { value: formatCurrency(numberValue), isCurrency: true };
}

function replaceCharacters(value: string, replace: [string, string]): string {
  return value.replace(new RegExp(replace[0], 'g'), replace[1]);
}

/**
 * Class responsible for exporting data in various formats (PDF, XLSX, CSV).
 */
export class ReportExporter<T, S = Partial<T>> {
  private data: T[];

  private options: ExportOptions<S>;

  /**
   * Initializes the exporter with data and options.
   * @param {any[]} data - The data to be exported.
   * @param {ExportOptions} options - Configuration options for export.
   * @param {string} [options.reportName] - The name of the report.
   * @param {string} [options.subtitle] - The subtitle of the report.
   * @param {string[]} [options.fixedStartFields] - Columns that should appear at the beginning in the given order.
   * @param {string[]} [options.fixedEndFields] - Columns that should appear at the end in the given order.
   * @param {string[]} [options.visibleFields] - Fields to be displayed; if provided, `hiddenFields` is ignored.
   * @param {string[]} [options.hiddenFields] - Fields to be hidden (ignored if `visibleFields` is provided).
   * @param {Record<string, string>} [options.customFieldNames] - Object mapping field names to custom names for display.
   * @param {Record<string, Type>} [options.summaryData] - Additional data to be displayed in the report summary.
   * @param {PDFPageOrientation} [options.pdfPageOrientation] - The orientation of the PDF page. Defaults to 'landscape'.
   * @param {PDFPageSizes} [options.pdfPageSize] - The size of the PDF page. Defaults to 'LETTER'.
   * @param {Map<string, CustomFormat>} [options.customFormat] - Custom format for the fields. key is the field name and value is the custom format.
   *
   */
  constructor(data: T[], options: ExportOptions<S> = {}) {
    this.data = data;
    this.options = options;
  }

  /**
   * Formats field names using custom names if provided.
   * @param {string} field - The field name to format.
   * @returns {string} - The formatted field name.
   */
  private formatFieldName(field: string): string {
    return (
      this.options.customFieldNames?.[field] || field.toUpperCase().replace(/_/g, ' ')
    );
  }

  /**
   * Retrieves the custom formatting configuration for a given field.
   * If the field is not found, it searches for the first key containing the field name.
   * If no matching key is found, it returns default formatting values.
   *
   * @param {string} name - The name of the field to retrieve the formatting for.
   * @returns {CustomFormat} - The formatting configuration for the field.
   */
  private getCustomFormatForField(name: string): CustomFormat {
    const { customFormat } = this.options;
    const keys = Array.from(customFormat?.keys() ?? []);
    let format = customFormat?.get(name);

    if (!format) {
      const foundKey = keys.find(key => name.includes(key));
      format = foundKey ? customFormat?.get(foundKey) : undefined;
    }

    return {
      parseCurrency: format?.parseCurrency ?? true,
      prepend: format?.prepend ?? '',
      append: format?.append ?? '',
      replace: format?.replace,
      textAlign: format?.textAlign,
    };
  }

  /**
   * Applies custom formatting to the given value.
   *
   * @param {string} name - The name of the field to format.
   * @param {T[keyof T]} value - The value to be formatted.
   * @returns {parseResult} - The formatted value with its attributes.
   */
  private formatField(name: string, value: T[keyof T] | S[keyof S]): parseResult {
    const {
      parseCurrency = true,
      prepend = '',
      append = '',
      textAlign,
      replace,
    } = this.getCustomFormatForField(name);

    const stringValue = value?.toString() ?? '';
    const parsedCurrency = parseIfCurrency(stringValue);

    let formattedValue = parseCurrency ? parsedCurrency.value : stringValue;
    const textAlignField =
      textAlign ??
      (parsedCurrency.isCurrency ? FieldTextAlignment.RIGHT : FieldTextAlignment.LEFT);

    if (replace) {
      formattedValue = replaceCharacters(formattedValue, replace);
    }

    if (!formattedValue || formattedValue === 'null' || formattedValue === 'undefined') {
      formattedValue = '';
    }

    return {
      value: `${prepend}${formattedValue}${append}`,
      isCurrency: parseCurrency && parsedCurrency.isCurrency,
      textAlign: textAlignField,
    };
  }

  /**
   * Exports data to CSV format and triggers a file download.
   */
  private exportToCSV(): void {
    const orderedFields = this.getOrderedFields();
    const { reportName = 'Report', summaryData = {} as Partial<S> } = this.options;

    const csvData = this.data.map((row: T) => {
      const formattedRow: Record<string, string> = {};

      orderedFields.forEach(field => {
        const value = row[field as keyof T] ?? ('' as T[keyof T]);
        formattedRow[this.formatFieldName(field)] = this.formatField(field, value).value;
      });

      return formattedRow;
    });

    if (Object.keys(summaryData).length > 0) {
      const summaryRow: Record<string, string> = {};

      orderedFields.forEach(field => {
        const value = summaryData[field as keyof S] ?? ('' as S[keyof S]);
        summaryRow[this.formatFieldName(field)] = this.formatField(field, value).value;
      });

      csvData.push(summaryRow);
    }

    const csv = jsonToCSV(csvData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${reportName}.csv`);
  }

  /**
   * Exports data to XLSX format and triggers a file download.
   */
  private exportToXLSX(): void {
    const orderedFields = this.getOrderedFields();
    const { reportName = 'Report', summaryData = {} as Partial<S> } = this.options;

    const formattedData = this.data.map((row: T) => {
      const formattedRow: Record<string, string> = {};

      orderedFields.forEach(field => {
        const value = row[field as keyof T] ?? ('' as T[keyof T]);
        formattedRow[this.formatFieldName(field)] = this.formatField(field, value).value;
      });

      return formattedRow;
    });

    if (Object.keys(summaryData).length > 0) {
      const summaryRow: Record<string, string> = {};

      orderedFields.forEach(field => {
        const value = summaryData[field as keyof S] ?? ('' as S[keyof S]);
        summaryRow[this.formatFieldName(field)] = this.formatField(field, value).value;
      });

      formattedData.push(summaryRow);
    }

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    worksheet['!cols'] = orderedFields.map(() => ({ wch: 18 }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
    });

    saveAs(blob, `${reportName}.xlsx`);
  }

  /**
   * Exports data to PDF format and triggers a file download.
   */
  private async exportToPDF(): Promise<void> {
    const orderedFields = this.getOrderedFields();
    const {
      reportName = 'Report',
      subtitle = '',
      pdfPageOrientation = PDFPageOrientation.LANDSCAPE,
      pdfPageSize = PDFPageSizes.LETTER,
      summaryData = {} as Partial<S>,
    } = this.options;

    const summaryExists = Object.keys(summaryData).length > 0;

    const MyDocument = React.createElement(
      Document,
      null,
      React.createElement(
        Page,
        { size: pdfPageSize, orientation: pdfPageOrientation, style: { padding: 15 } },
        React.createElement(
          View,
          { style: { alignItems: 'center', marginBottom: 10 } },
          React.createElement(
            Text,
            { style: { fontSize: 11, fontWeight: 'bold' } },
            reportName,
          ),
          React.createElement(
            Text,
            { style: { fontSize: 9, marginBottom: 10 } },
            subtitle,
          ),
        ),
        React.createElement(
          View,
          {
            style: {
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid black',
            },
          },
          React.createElement(
            View,
            {
              style: {
                flexDirection: 'row',
                borderBottom: '1px solid black',
                backgroundColor: '#eee',
              },
            },
            orderedFields.map((field, index) =>
              React.createElement(
                Text,
                {
                  key: field,
                  style: {
                    flex: 1,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 8,
                    borderRight:
                      index !== orderedFields.length - 1 ? '1px solid black' : 'none',
                    padding: 3,
                  },
                },
                this.formatFieldName(field),
              ),
            ),
          ),
          this.data.map((row: T) =>
            React.createElement(
              View,
              {
                style: {
                  flexDirection: 'row',
                  borderBottom: '1px solid black',
                },
              },
              orderedFields.map((field, colIndex) => {
                const value = row[field as keyof T] ?? ('' as T[keyof T]);
                const formatted = this.formatField(field, value);

                return React.createElement(
                  Text,
                  {
                    key: field,
                    style: {
                      flex: 1,
                      textAlign: formatted.textAlign,
                      fontSize: 8,
                      padding: 3,
                      borderRight:
                        colIndex !== orderedFields.length - 1
                          ? '1px solid black'
                          : 'none',
                    },
                  },
                  formatted.value,
                );
              }),
            ),
          ),
          summaryExists &&
            React.createElement(
              View,
              {
                style: {
                  flexDirection: 'row',
                  borderTop: '1px solid black',
                },
              },
              orderedFields.map((field, colIndex) => {
                const value = summaryData[field as keyof S] ?? ('' as S[keyof S]);
                const formatted = this.formatField(field, value);

                return React.createElement(
                  Text,
                  {
                    key: field,
                    style: {
                      flex: 1,
                      textAlign: formatted.textAlign,
                      fontSize: 8,
                      padding: 3,
                      borderRight:
                        colIndex !== orderedFields.length - 1
                          ? '1px solid black'
                          : 'none',
                      fontWeight: 'bold',
                    },
                  },
                  formatted.value,
                );
              }),
            ),
        ),
      ),
    );

    const blob = await pdf(MyDocument).toBlob();
    saveAs(blob, `${reportName}.pdf`);
  }

  /**
   * Determines the correct order of fields based on user-provided options.
   * @returns {string[]} - The ordered fields array.
   */
  private getOrderedFields(): string[] {
    const allFields = Object.keys(this.data[0] || {});
    const {
      fixedStartFields = [],
      fixedEndFields = [],
      visibleFields = [],
      hiddenFields = [],
    } = this.options;

    const finalVisibleFields =
      visibleFields.length > 0
        ? visibleFields
        : allFields.filter(field => !hiddenFields.includes(field));

    const dynamicFields = finalVisibleFields.filter(
      field => !fixedStartFields.includes(field) && !fixedEndFields.includes(field),
    );
    return [...fixedStartFields, ...dynamicFields, ...fixedEndFields].filter(field =>
      finalVisibleFields.includes(field),
    );
  }

  /**
   * Exports data to the specified format and triggers a file download.
   * @param {DocumentExportType} exportType - The type of export to perform.
   */
  async export(exportType: DocumentExportType): Promise<void> {
    switch (exportType) {
      case DocumentExportType.CSV:
        this.exportToCSV();
        break;
      case DocumentExportType.XLSX:
        this.exportToXLSX();
        break;
      case DocumentExportType.PDF:
        await this.exportToPDF();
        break;
      default:
        throw new Error(`Unsupported export type: ${exportType}`);
    }
  }
}
