import { FC, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { MultiSelect, Option } from 'react-multi-select-component';
import { Roles, SelectType, Permissions, RolesForm } from '../../../modules/interfaces';

import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../modules/utils/transform';

type AddNewRoleProp = {
  show: boolean;
  isEditRole?: boolean;
  onHide: () => void;
  onSave: () => void;
  actionRole: Roles | undefined;
};

const AddNewRole: FC<AddNewRoleProp> = ({
  show,
  onHide,
  onSave,
  isEditRole,
  actionRole,
}) => {
  const [permissionsList, setPermissionsList] = useState<Permissions[]>([]);
  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    reset,

    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      permissions: [] as Option[],
      components: [] as Option[],
    },
  });

  const [{ loading: insertLoading, data: insertResult, error: insertError }, insertRole] =
    useRequest<Roles>(
      endpoints.ROLES,
      'post',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const [{ loading: updateLoading, data: updateResult, error: updateError }, updateRole] =
    useRequest<Roles>(
      `${endpoints.ROLES}/${actionRole?.uuid}`,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true, autoCancel: false },
    );

  const [{ data: permissionResult }] = useRequest<Permissions[]>(
    endpoints.PERMISSIONS,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  const componentMultiselect = watch('permissions');

  const onComponentChange = (editorState: Option[]) => {
    setValue('permissions', editorState);
  };

  const onModalCancel = () => {
    reset({
      name: '',
      permissions: [] as Option[],
      components: [] as Option[],
    });
    onHide();
  };

  const getPropertyDropDownData = () => {
    const dropDownList: SelectType[] = [];

    permissionsList.forEach(item => {
      dropDownList.push({
        value: item.uuid,
        label: item.name,
      });
    });
    return dropDownList;
  };

  const saveRole = (data: RolesForm) => {
    if (isEditRole) {
      updateRole({
        data: {
          name: data.name,
          permissions: data.permissions.map((item: Option) => item.value),
          components: [],
        },
      });
    } else {
      insertRole({
        data: {
          name: data.name,
          permissions: data.permissions.map((item: Option) => item.value),
          components: [],
        },
      });
    }
  };

  useEffect(() => {
    if (!permissionResult) return;

    setPermissionsList(permissionResult.result);
  }, [permissionResult]);

  useEffect(() => {
    register('permissions', { required: 'This is required' });
  }, [register]);

  useEffect(() => {
    if (!actionRole || !show) return;

    reset({
      name: actionRole?.name || '',
      permissions: [] as Option[],
      components: [] as Option[],
    });

    if (actionRole?.components) {
      const selectedCategories = actionRole?.permissions.map(item => ({
        value: item.uuid,
        label: item.name,
      }));

      onComponentChange(selectedCategories);
    }
  }, [actionRole, show]);

  useEffect(() => {
    if (insertResult || updateResult) {
      onSave();
      onHide();
    }
    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [insertResult, updateResult, insertError, updateError]);

  const onSubmit = async (data: RolesForm) => {
    const validated = await trigger(['name', 'permissions', 'components']);

    if (validated) {
      saveRole(data);
    }
  };

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="edit-guest-modal-title">
            {isEditRole ? 'Edit role' : 'Add new role'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label className="editor-title-label">Role name</Form.Label>
          <Form.Control
            className="editor-control"
            placeholder="Enter Article name"
            {...register('name', { required: 'This is required' })}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <span className="error-text">{message}</span>}
          />

          {permissionsList?.length !== 0 && (
            <Row className="mt-24px">
              <Form.Group className="border-bottom">
                <Form.Label className="editor-title-label">Permissions</Form.Label>
                <MultiSelect
                  options={getPropertyDropDownData()}
                  value={componentMultiselect}
                  onChange={(values: Option[]) => {
                    onComponentChange(values);
                  }}
                  labelledBy="Select"
                />
                <ErrorMessage
                  errors={errors}
                  name="permissions"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Form.Group>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="edit-guest-modal-button" onClick={onModalCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="edit-guest-modal-button-save"
            disabled={insertLoading || updateLoading}
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddNewRole;
