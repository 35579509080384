import { useEffect, useState, ChangeEvent, FC } from 'react';
import { Form } from 'react-bootstrap';
import { MultiSelect, Option } from 'react-multi-select-component';

import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { ModalType } from '../../../modules/enums/types';
import { Roles } from '../../../modules/interfaces';
import { endpoints } from '../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { RoleOptions } from '../NewRoleModal';
import { ModalComponent } from '../UserModals';

import './styles/index.css';

export interface RoleManagementModalData {
  handleClose: () => void;
  handleRefresh: () => void;
  modalType: ModalType;
  modalName: string;
  cancelButton: string;
  saveButton: string;
  data?: Roles;
}

interface RoleModalProps {
  type: ModalType;
}

interface RoleModalBodyProps extends RoleModalProps {
  roleName: string;
  rolePermissions: Option[];
  onChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
  setPermission: React.Dispatch<React.SetStateAction<Option[]>>;
}

const ModalBody: FC<RoleModalBodyProps> = ({
  type,
  roleName,
  rolePermissions,
  onChangeName,
  setPermission,
}) => {
  let component: JSX.Element | null = null;

  if (type === ModalType.EDIT) {
    component = (
      <div className="edit--role-form">
        <Form>
          <Form.Group>
            <Form.Label>Role Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Default"
              value={roleName}
              onChange={onChangeName}
            />
          </Form.Group>
          <Form.Group className="permission-multiselector">
            <Form.Label>Permissions</Form.Label>
            <MultiSelect
              options={RoleOptions}
              value={rolePermissions}
              onChange={setPermission}
              labelledBy="Default"
            />
          </Form.Group>
          <Form.Group className="d-none">
            <Form.Label>Assigned Properties</Form.Label>
            <Form.Label>Assigned Users</Form.Label>
            <MultiSelect
              options={[]}
              value={[]}
              onChange={() => {
                // TODO: need to implement
              }}
              labelledBy="Select"
            />
          </Form.Group>
        </Form>
      </div>
    );
  } else if (type === ModalType.DELETE) {
    component = <p className="text-center">Are you sure you want to delete this role?</p>;
  }

  return component;
};
const ModalTitle: FC<RoleModalProps> = ({ type }) => {
  let component: JSX.Element | null = null;

  if (type === ModalType.EDIT) {
    component = (
      <h4>
        Edit Role <span className="d-block">Edit this Role</span>
      </h4>
    );
  } else if (type === ModalType.DELETE) {
    component = <h4>Delete Role</h4>;
  }

  return component;
};

export const RoleManagementModal: FC<RoleManagementModalData> = ({
  modalName,
  cancelButton,
  saveButton,
  data,
  modalType,
  handleClose,
  handleRefresh,
}) => {
  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;
  const { setLoading } = useLoading()!;

  const [showModal, setShowModal] = useState('');
  const [footerBtn1, setFooterBtn1] = useState('');
  const [footerBtn2, setFooterBtn2] = useState('');

  const [roleName, setRoleName] = useState('');
  const [rolePermissions, setRolePermissions] = useState<Option[]>([]);

  const url = `${endpoints.ROLES}/${data?.uuid}`;
  const [{ loading: updateLoading, data: updateResult, error: updateError }, updateRole] =
    useRequest<Roles>(
      url,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const [{ loading: deleteLoading, data: deleteResult, error: deleteError }, deleteRole] =
    useRequest<Roles>(
      url,
      'delete',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  useEffect(() => {
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (deleteError) {
      const message = getApiErrorMessage(deleteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (updateResult) {
      setSimpleToasts({ type: 'info', message: 'Role Updated successly!', show: true });
      handleClose();
      handleRefresh();
    }

    if (deleteResult) {
      handleClose();
      handleRefresh();
    }
  }, [
    updateError,
    updateResult,
    deleteError,
    deleteResult,
    setSimpleToasts,
    handleClose,
    handleRefresh,
  ]);

  useEffect(() => {
    setLoading(updateLoading || deleteLoading);
  }, [updateLoading, deleteLoading, setLoading]);

  useEffect(() => {
    setShowModal(modalName);
    setFooterBtn1(cancelButton);
    setFooterBtn2(saveButton);
    if (data) {
      const { name, permissions } = data;

      setRoleName(name);

      const permissionOptions = permissions.map((permission): Option => {
        return { value: permission.uuid, label: permission.name };
      });

      setRolePermissions(permissionOptions);
    }
  }, [setShowModal, cancelButton, saveButton, data, modalName]);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleName(e.currentTarget.value);
  };

  let modalClass = '';

  if (showModal === 'Delete Role') {
    modalClass = 'delete--user-modal';
  } else if (showModal === 'Delete Warning') {
    modalClass = 'delete--modal-warning';
  } else if (showModal === 'Edit Role') {
    modalClass = 'user--role-modal new-role-modal edit--user-modal';
  }

  return (
    <div className="user--management-opt">
      <ModalComponent
        show={!!showModal}
        className={modalClass}
        handleClose={() => {
          setShowModal('');
          handleClose();
        }}
        handleSave={() => {
          if (modalType === ModalType.EDIT) {
            updateRole({
              data: {
                name: roleName,
                permissions: rolePermissions.map(item => item.value),
              },
            });
          } else if (modalType === ModalType.DELETE) {
            deleteRole({ data: {} });
          }
        }}
        aria-labelledby="contained-modal-title-vcenter"
        title={showModal}
        cancelButton={footerBtn1}
        saveButton={footerBtn2}
      >
        <ModalBody
          type={modalType}
          rolePermissions={rolePermissions}
          setPermission={setRolePermissions}
          onChangeName={onChangeName}
          roleName={roleName}
        />
        <ModalTitle type={modalType} />
      </ModalComponent>
    </div>
  );
};
