import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColDef } from 'ag-grid-community';
import { DataTable } from '../DataTable';
import { formatCurrency } from '../../../modules/utils/formatCurrency';

type ReservationsReportsTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: any;
};

export const ReservationsReportTable: FC<ReservationsReportsTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
}: ReservationsReportsTableProps) => {
  const navigate = useNavigate();

  const [columns] = useState<ColDef<any>[]>([
    { field: 'reservationId', headerName: 'RESERVATION', flex: 1 },
    {
      field: 'arrivalDate',
      headerName: 'ARRIVAL',
      flex: 1,
      sortable: true,
    },
    {
      field: 'departureDate',
      headerName: 'DEPARTURE',
      flex: 1,
      sortable: true,
    },
    {
      field: 'guestName',
      headerName: 'GUEST',
      flex: 1,
      sortable: true,
    },
    {
      field: 'roomName',
      headerName: 'ROOM',
      flex: 1,
      sortable: true,
    },
    {
      field: 'sales_addons',
      headerName: 'ADDONS SOLD',
      flex: 1,
      sortable: true,
    },
    {
      field: 'total_addons',
      headerName: 'ADDONS REVENUE',
      flex: 1,
      sortable: true,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },
    {
      field: 'total_rooms',
      headerName: 'ROOM REVENUE',
      sortable: true,
      flex: 1,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },
    {
      field: 'taxes',
      headerName: 'TAXES',
      sortable: true,
      flex: 1,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },
    {
      field: 'total',
      headerName: 'TOTAL',
      sortable: true,
      flex: 1,
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
    />
  );
};
