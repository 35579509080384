import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { FormProps } from 'react-router-dom';

import { Icon } from '../../Menu/TopMenu';

import './styles/styles.css';

export const SearchTableInput: FC<FormProps> = ({ ...rest }) => {
  return (
    <Form className="inbox-search d-flex align-items-center" {...rest}>
      <Icon iconName="Search" size={12} className="align-top search-icon" />

      <Form.Control
        className=" me-2 search-input"
        type="text"
        id="filter-text-box"
        placeholder={rest.placeholder || 'Search'}
        onInput={() => rest.onInput}
      />
    </Form>
  );
};

export const SearchInboxInput: FC<FormProps> = ({ ...rest }) => {
  return (
    <Form className="inbox-search d-flex align-items-center" {...rest}>
      <Icon iconName="Search" size={12} className="align-top search-icon" />
      <Form.Control
        type="search"
        placeholder={rest.placeholder || 'Search'}
        className="me-2 search-input"
      />
    </Form>
  );
};

export const SearchInput: FC<FormProps> = ({ ...rest }) => {
  return (
    <Form className="table-search d-flex align-items-center" {...rest}>
      <Icon iconName="Search" size={12} className="align-top search-icon" />
      <Form.Control
        type="search"
        placeholder={rest.placeholder || 'Search'}
        className="me-2 search-input"
      />
    </Form>
  );
};

export const DashboardSearchInput: FC<FormProps> = ({ className, ...rest }) => {
  return (
    <Form className="d-flex article-search-form align-items-center" {...rest}>
      <Icon iconName="Search" size={18} className="align-top search-icon" />
      <Form.Control
        type="search"
        placeholder="Search Article"
        className="me-2 article-search-input"
        aria-label="Search"
      />
    </Form>
  );
};
