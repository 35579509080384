import { FC, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { MultiSelect, Option } from 'react-multi-select-component';
import { ErrorMessage } from '@hookform/error-message';
import { PackagesResponse } from '../../../modules/interfaces';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  getEarlyCheckParams: (values: PackagesResponse, params: Option[]) => void;
}

export const AddPackageModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  getEarlyCheckParams: addPackage,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: { name: '', description: '' },
  });
  const [selectedPackageOption, setSelectedPackageOption] = useState<Option[]>([]);
  const [disabledSize, setDisabledSize] = useState(false);
  const [disabledRange, setDisabledRange] = useState(false);

  const onSubmit = async (data: PackagesResponse) => {
    const validated = await trigger(['name']);

    if (validated) {
      addPackage(data, selectedPackageOption);
      setSelectedPackageOption([]);

      reset();
      handleClose();
    }
  };

  useEffect(() => {
    const isRange = !!selectedPackageOption.find(item => item.value === 'RANGE');
    const isSize = !!selectedPackageOption.find(item => item.value === 'SIZE');

    if (isRange) {
      setDisabledSize(true);
    } else if (!isRange && disabledSize) {
      setDisabledSize(false);
    }

    if (isSize) {
      setDisabledRange(true);
    } else if (!isSize && disabledRange) {
      setDisabledRange(false);
    }
  }, [selectedPackageOption, disabledRange, disabledSize]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title className="editor-title">Create Add-on</Modal.Title>
        <span className="editor-sub-title">Add new add-on</span>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Label className="editor-title-label">Name</Form.Label>
              <Form.Control
                className="editor-control "
                placeholder="Enter the add-on name..."
                {...register('name', { required: 'This is required.' })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Col>
          </Row>
          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">Description</Form.Label>
              <Form.Control
                className="editor-control"
                placeholder="Enter a description..."
                {...register('description')}
              />
            </Col>
          </Row>
          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">Add a parameter</Form.Label>
              <MultiSelect
                disableSearch
                hasSelectAll={false}
                options={[
                  { label: 'Quantity', value: 'QUANTITY' },
                  { label: 'Advanced Notice', value: 'ADVANCED NOTICE' },
                  { label: 'Size', value: 'SIZE', disabled: disabledSize },
                  { label: 'Range', value: 'RANGE', disabled: disabledRange },
                ]}
                value={selectedPackageOption}
                labelledBy="Default"
                onChange={setSelectedPackageOption}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button className="cancel-button" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="save-button" onClick={handleSubmit(onSubmit)}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
