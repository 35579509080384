import { FC, useEffect, useState, useContext } from 'react';

import { ColDef, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { BadgeCellRenderer, DataTable } from '../DataTable';
import {
  useAuth,
  useRequest,
  useLoading,
  useNotifications,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { endpoints, routes } from '../../../modules/mappers/urls';
import { config } from '../../../modules/config';
import {
  MewsOrderItem,
  Reservations,
  ReservationsAPIResponse,
} from '../../../modules/interfaces';
import { numberSort, roomSort } from '../../../modules/utils/tableSorts';
import { UserPropertiesContext } from '../../../modules/context/userPropertiesContext';

export const ReservationsTable: FC = () => {
  const navigate = useNavigate();
  const { setSimpleToasts } = useNotifications()!;
  const [searchString] = useState<string>('');
  const [tableData, setTableData] = useState<Reservations[]>([]);
  const [cursor, setCursor] = useState<string>('');
  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;
  const { selectedProperty } = useContext(UserPropertiesContext)!;

  const [{ data, loading, error }, refetch] = useRequest<ReservationsAPIResponse>(
    endpoints.MEWS_RESERVATIONS,
    'get',
    {
      authToken: credentialsInfo?.token,
      propertyId: selectedProperty?.property.uuid,
    },
    { manual: true },
  );

  const refreshHandler = async () => {
    await refetch({
      url: `${endpoints.MEWS_RESERVATIONS}?Cursor=${cursor}`,
    });
  };

  useEffect(() => {
    if (selectedProperty) {
      refetch();
    }
  }, [selectedProperty]);

  useEffect(() => {
    const isLoading = loading;
    setLoading(isLoading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (data) {
      const { result } = data;
      setTableData((prevData: Reservations[]) => prevData.concat(result.Reservations));
      setCursor(result.Cursor);
    }
  }, [data, error, setSimpleToasts]);

  const [columns] = useState<ColDef<Reservations>[]>([
    {
      valueGetter: params => {
        const { data: reservation } = params;
        const resource = reservation?.Resource;
        const name = resource
          ? `${resource.Name} -  ${resource.ResourceName}`
          : 'Not assigned';

        return name;
      },
      headerName: 'ROOM',

      sortable: true,
      comparator: (valueA, valueB) => roomSort(valueA, valueB),
      flex: 1,
    },
    {
      valueGetter: params => {
        const { data: reservation } = params;
        const customer = reservation?.Customer;
        const name = customer ? `${customer.FirstName} ${customer.LastName}` : '-';

        return name;
      },

      headerName: 'GUEST NAME',

      sortable: true,
      flex: 1,
    },

    {
      field: 'ScheduledStartUtc',
      headerName: 'ARRIVAL DATE',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<Reservations, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromISO(value || '').toFormat('dd LLL yyyy');

        return formattedDate;
      },
      cellStyle: {
        color: '#757474',
      },
      flex: 1,
    },
    {
      field: 'EndUtc',
      headerName: 'DEPARTURE DATE',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<Reservations, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromISO(value || '').toFormat('dd LLL yyyy');

        return formattedDate;
      },
      cellStyle: {
        color: '#757474',
      },
      flex: 1,
    },
    {
      field: 'OrderItem',
      headerName: 'AMOUNT',
      sortable: true,
      comparator: (valueA, valueB) =>
        numberSort(valueA.Amount.NetValue, valueB.Amount.NetValue),

      valueFormatter: (params: ValueFormatterParams<Reservations, MewsOrderItem>) => {
        const { value } = params;
        if (!value) {
          return '';
        }
        const amount =
          value?.Amount?.NetValue?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }) || `$0.00`;
        return amount;
      },
      flex: 1,
    },
    {
      field: 'State',
      headerName: 'STATUS',
      sortable: true,
      cellRenderer: BadgeCellRenderer,
      flex: 1,
    },
  ]);

  return (
    <>
      <DataTable
        refreshHandler={refreshHandler}
        searchString={searchString}
        changeState={0}
        rowData={tableData}
        columnDefs={columns}
        pagination
        paginationPageSize={config.tables.reservations.paginationSize}
        onRowClicked={(e: RowClickedEvent<Reservations>) => {
          if (e.api.getFocusedCell()?.column.getColId() === 'action') {
            return;
          }

          const { data: reservation } = e;

          let address = 'Not provided';

          if (reservation?.Customer?.Address?.City) {
            const { Line1, City, CountrySubdivisionCode } = reservation.Customer.Address;

            address = [Line1, City, CountrySubdivisionCode].filter(Boolean).join(', ');
          }

          if (reservation) {
            const {
              Id: reservationId,
              State: status,
              Customer,
              Resource,
              ScheduledStartUtc,
              EndUtc,
              CreatedUtc: createdAt,
            } = reservation;
            const amountOfNights =
              EndUtc && ScheduledStartUtc
                ? DateTime.fromISO(EndUtc)
                    .startOf('day')
                    .diff(DateTime.fromISO(ScheduledStartUtc).startOf('day'), 'days').days
                : 0;
            const room = Resource ? `${Resource.Name} -  ${Resource.ResourceName}` : '';

            navigate(
              routes.RESERVATION_DETAILS.replace(':reservationId', reservationId),
              {
                state: {
                  reservationId,
                  status,
                  phoneNumber: Customer?.Phone || '',
                  email: Customer?.Email || '',
                  guestName: `${Customer?.FirstName} ${Customer?.LastName}`,
                  room,
                  checkIn: ScheduledStartUtc,
                  checkOut: EndUtc,
                  createdAt,
                  amountOfNights,
                  address,
                },
              },
            );
          }
        }}
      />
      <Container className="table-header" fluid style={{ padding: '16px' }}>
        <Row className="m-0 justify-content-end w-100 align-items-center">
          <Col className="p-0">
            <Button className="float-right" onClick={refreshHandler}>
              Load More
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
